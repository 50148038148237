import styled from "styled-components";
import { Link as _Link } from "react-router-dom";

export const Wrap = styled.div`
  background-color: white;
  height: 6.9rem;
  display: flex;
  align-items: center;
`;

export const LogoContainer = styled.div`
  padding: 1rem 1.75rem 1rem 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
  }
`;

export const Logo = styled.img`
  max-width: 16rem;
  display: inline-block;
  margin-bottom: -0.3rem;
  margin-left: -0.6rem;
`;

export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2.5rem;
`;

export const Button = styled.button`
  background: transparent;
  border: 0;
  border-radius: 0.2rem;
  color: inherit;
  font-weight: bold;
  text-transform: uppercase;
  padding: 1rem;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  display: flex;

  &:hover {
    background-color: #eee;
  }
`;

export const Link = styled(_Link)`
  font-size: 11px;
  border: 0;
  border-radius: 0.2rem;
  color: inherit;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  display: flex;

  &:hover {
    background-color: #eee;
  }
`;
