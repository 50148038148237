import styled from "styled-components";

export const Checkbox = styled.input`
  &[type="checkbox"] {
    -webkit-appearance: none;
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    position: relative;
    cursor: pointer;
    border-radius: 0;
    background-color: ${({ theme }) => theme.colors.whiteSmoke};
    margin: 0;
    margin-right: 1.5rem;
    outline: none;

    &:checked {
      background-color: ${({ theme }) => theme.colors.cardinal};
    }

    &:before,
    &:checked:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 2rem;
      text-align: center;
      color: #fff;
    }

    &:checked:before {
      content: "✔";
    }
  }
`;

export const CheckboxWrap = styled.div`
  display: flex;

  ${({ pad }) =>
    pad
      ? `
    margin: 20px 0;
  `
      : ``}
`;

export const CheckboxLabel = styled.label`
  flex: 1;
  font-weight: normal;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.4;
`;
