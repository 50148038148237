import { all } from "redux-saga/effects";
import authSaga from "./auth/auth.saga";
import ideSaga from "./ide/ide.saga";
import adminSaga from "./admin/admin.saga";
import examSaga from "./exam/exam.saga";
import enrolmentSaga from './enrolment/enrolment.saga';
import billingSaga from './billing/billing.saga';

function* sagas() {
  yield all([authSaga(), ideSaga(), adminSaga(), examSaga(), enrolmentSaga(), billingSaga()]);
}

export default sagas;
