import { createSlice } from "redux-starter-kit";

const billingSlice = createSlice({
  slice: "billing",
  initialState: {

  },
  reducers: {

  }
});

export const { actions, reducer } = billingSlice;
export const {} = actions;
export default reducer;
