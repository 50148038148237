import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { checkIsAdmin as _checkIsAdmin } from "../redux/admin/admin.slice";
import { Card } from "./styled/Dashboard.styled";
import Breadcrumbs from './admin-pages/Breadcrumbs';
import adminRoutes from './admin-pages/routes';

class Admin extends Component {
  componentDidMount() {
    const { checkIsAdmin } = this.props;

    checkIsAdmin();
  }

  render() {
    const { loading, authorised } = this.props;

    if (loading) {
      return <Card to="#" onClick={(e) => e.preventDefault()} loading>Loading...</Card>;
    }

    if (!authorised) {
      return <Redirect to="/" />;
    }

    return (
      <>
        <Breadcrumbs />
        <Switch>
          {adminRoutes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
        </Switch>
      </>
    );
  }
}

const mapStateToProps = ({ admin: { loading, authorised } }) => ({
  loading,
  authorised
});

const mapDispatchToProps = {
  checkIsAdmin: _checkIsAdmin
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
