import React from "react";
import { Link, withRouter, matchPath } from "react-router-dom";
import { Title } from '../styled/Title.styled';
import adminRoutes from './routes';

const getCurrentRouteBreadcrumb = (location) => {
  const routeIndex = adminRoutes.findIndex((route) => (
    matchPath(location.pathname, { exact: true, strict: false, path: route.path })
  ));

  if (routeIndex < 0) {
    return ''
  }

  return ` > ${adminRoutes[routeIndex].title}`;
};

const AdminBreadcrumbs = ({ location }) => (
  <Title>
    <Link to="/admin">Admin</Link>
    {getCurrentRouteBreadcrumb(location)}
  </Title>
);

export default withRouter(AdminBreadcrumbs);
