import React from "react";
import Markdown from "markdown-to-jsx";
import EmojiConverter from "emoji-js";
import { Code, YouTube } from "../guide-components";
import { MarkdownStyles } from "../styled/GHMarkdown.styled";

const Content = ({ content }) => {
  const emojiConverter = new EmojiConverter();
  const formattedContent = emojiConverter.replace_colons(content || "");

  return (
    <MarkdownStyles>
      <Markdown
        options={{
          overrides: {
            pre: {
              component: Code
            },
            YouTube: {
              component: YouTube
            },
            a: {
              props: {
                target: "_blank"
              }
            }
          }
        }}
      >
        {formattedContent || ""}
      </Markdown>
    </MarkdownStyles>
  );
};

export default Content;
