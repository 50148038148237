import React, { Component } from "react";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import { Input, Label, Form, Row, Panel, TextArea } from "../styled/Form.styled";
import { List, ListItem, Controls, ChapterName, PaddedContainer } from "../styled/AdminChapters.styled";
import { Wrap, Inner } from "../styled/Chapters.styled";
import { Button, IconButton } from "../styled/Button.styled";
import * as Icons from "../icons";
import IconPicker from '../IconPicker';
import "react-mde/lib/styles/css/react-mde-all.css";
import Delete from "../icons/Delete";
import CheckCircle from "../icons/CheckCircle";

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

class PageEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "write"
    };
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
  }

  handleTabChange = (selectedTab) => {
    this.setState({ selectedTab });
  };

  render() {
    const { selectedTab } = this.state;
    const {
      title,
      content,
      icon,
      videoUrl,
      onFieldChange,
      onSubmit,
      onClose,
      quiz,
      onQuizAddQuestion,
      onQuizRemoveQuestion,
      onQuizUpdateQuestion,
      onQuizAddAnswer,
      onQuizQuizRemoveAnswer,
      onQuizUpdateAnswer,
      onQuizToggleCorrectAnswer,
    } = this.props;

    return (
      <Wrap>
        <Inner>
          <Form>
            <Row>
              <Label>Page Title</Label>
              <Input
                name="title"
                onChange={onFieldChange}
                value={title}
              />
            </Row>
            <Row>
              <Label>Content</Label>
              <ReactMde
                value={content}
                onChange={(value) => {
                  onFieldChange({
                    target: {
                      name: "content",
                      value
                    }
                  })
                }}
                selectedTab={selectedTab}
                onTabChange={this.handleTabChange}
                generateMarkdownPreview={markdown =>
                  Promise.resolve(converter.makeHtml(markdown))
                }
                minEditorHeight={400}
              />
            </Row>
            <Row>
              <Label>Video</Label>
              <Input
                name="videoUrl"
                onChange={onFieldChange}
                placeholder="Leave empty if you don't want this to just be a video"
                value={videoUrl}
              />
            </Row>
            <Row>
              <Label>Quiz</Label>
              {quiz.map((question, questionIndex) => (
                <Panel key={`questionIndex_${question.question}`}>
                  <PaddedContainer>
                    <Label>Question:</Label>
                    <TextArea
                      type="text"
                      name="name"
                      value={question.question}
                      onChange={e => onQuizUpdateQuestion(questionIndex, e.target.value)}
                    />
                    <ChapterName>Answers:</ChapterName>
                    <List>
                      {question.answers.map((answer, answerIndex) => (
                        <ListItem key={answerIndex}>
                          <Input
                            type="text"
                            name="name"
                            value={answer}
                            onChange={e => onQuizUpdateAnswer(questionIndex, answerIndex, e.target.value)}
                          />
                          <Controls>
                            <IconButton
                              type="button"
                              title="Toggle correct"
                              onClick={() => onQuizToggleCorrectAnswer(questionIndex, answerIndex)}
                              color={question.correctAnswers.includes(answerIndex) ? 'seaGreen' : 'black'}
                            >
                              <CheckCircle />
                            </IconButton>
                            <IconButton
                              type="button"
                              title="Remove"
                              onClick={() => onQuizQuizRemoveAnswer(questionIndex, answerIndex)}
                            >
                              <Delete />
                            </IconButton>
                          </Controls>
                        </ListItem>
                      ))}
                    </List>
                    <PaddedContainer>
                      <Button
                        type="button"
                        color="seaGreen"
                        onClick={() => onQuizAddAnswer(questionIndex)}
                      >
                        Add Answer
                      </Button>
                    </PaddedContainer>
                    <Button
                      type="button"
                      color="orangeSoda"
                      onClick={() => onQuizRemoveQuestion(questionIndex)}
                    >
                      Remove Question
                    </Button>
                  </PaddedContainer>
                </Panel>
              ))}
              <Button
                type="button"
                onClick={onQuizAddQuestion}
              >
                Add Question
              </Button>
            </Row>
            <Row>
              <Label>Icon</Label>
              <IconPicker
                name="icon"
                onChange={onFieldChange}
                value={icon}
                icons={Object.entries(Icons).map(([name, Icon]) => ({ name, Icon }))}
              />
            </Row>
            <Button type="button" color="seaGreen" onClick={onSubmit}>
              Submit
            </Button>
            <Button type="button" color="orangeSoda" onClick={onClose} style={{ position: 'absolute', top: '2.5rem', right: '2.5rem' }}>
              X
            </Button>
          </Form>
        </Inner>
      </Wrap>
    );
  }
}

export default PageEditor;
