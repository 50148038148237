import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import firestore from '../firebase';
import { Wrap, Inner, Card, CardType, Block } from "./styled/Dashboard.styled";
import { Title } from "./styled/Title.styled";
import exams from "../data/exams.json";

class Dashboard extends Component {
  state = {
    courses: [],
    loading: false,
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      await this.fetchCourses();
      await this.fetchModules();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  async fetchCourses() {
    const { user, admin } = this.props;
    let courses = [];

    if (admin) {
      // admin users get all courses
      const { docs } = await firestore().collection('courses').get();

      courses = docs.map(d => ({
        id: d.id,
        ...d.data()
      }));
    } else {
      if (!user.course) {
        return [];
      }

      const doc = await firestore().collection('courses').doc(user.course.id).get();

      courses = [{
        id: doc.id,
        ...doc.data()
      }];
    }

    this.setState({ courses });
  }

  async fetchModules() {
    const { courses } = this.state;

    const ids = [...new Set(
      courses.reduce((acc, course) => [...acc, ...course.modules], [])
    )];

    const modules = await Promise.all(ids.map(async (id) => {
      const doc = await firestore().collection('modules').doc(id);
      const mod = await doc.get();
      return {
        id: mod.id,
        ...mod.data()
      };
    }));

    this.setState(((state) => ({
      courses: state.courses.map((course) => ({
        ...course,
        modules: course.modules.map((id) => modules.find((mod) => mod.id === id)),
      }))
    })));
  }

  renderAccountSection() {
    const { user } = this.props;
    return (
      <Block>
        <Title>Account</Title>
        {user.enrolled ? (
          <Card color="yellow" to="/payments">Payments</Card>
        ) : (
          <Card to="/enrol">Enrol</Card>
        )}
      </Block>
    )
  }

  renderExams() {
    return (
      <Block>
        <Title>Exams</Title>
        {exams.map(exam => (
          <Card key={exam.id} to={`/exams/${exam.slug}`}>
            {exam.name}
          </Card>
        ))}
      </Block>
    );
  }

  renderCourses() {
    const { courses } = this.state;
    return (
      courses.map((course) => (
        <Block key={course.id}>
          <Title>{course.name}</Title>
          {course.modules.map((mod) => (
            <Card key={mod.id} to={`/module/${mod.slug}`}>
              {mod.name}
              <CardType>{mod.duration} weeks</CardType>
            </Card>

          ))}
        </Block>
      ))
    );
  }

  render() {
    const { user } = this.props;
    const { loading } = this.state;

    return (
      <Fragment>
        <Title>Welcome, {user.firstName}</Title>
        <Wrap>
          <Inner>
            {loading ? (
              <Card to="#" onClick={(e) => e.preventDefault()} loading>Loading...</Card>
            ) : (
              <Fragment>
                {user.enrolled && user.paid ? this.renderCourses() : null}
                {user.eligible ? this.renderAccountSection() : this.renderExams()}
              </Fragment>
            )}
          </Inner>
        </Wrap>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ auth: { user }, admin: { authorised } }) => ({
  user,
  admin: authorised,
});

export default connect(mapStateToProps)(Dashboard);
