import React from 'react';
import Icon from './Icon';

const ChevronUp = (props) => (
  <Icon
    {...props}
    path="M512 342l256 256-60 60-196-196-196 196-60-60z"
  />
);

export default ChevronUp;
