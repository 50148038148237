import styled from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';

export const SidebarLayout = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  padding: 1.25rem 2.5rem;

  @media screen and (min-width: ${({ theme }) => `${theme.breakpoints.b}px` }){
    column-gap: 1.25rem;
    grid-template-columns: ${({ right = false }) => right ? 'auto 300px' : '300px auto'};
    grid-template-rows: auto;
  }
`;

export const Sidebar = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 1.25rem;
`;

export const SidebarList = styled.div`
  max-height: 540px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
   -ms-overflow-style: none; /* IE */
  ::-webkit-scrollbar { /* Chrome */
    display: none;
  }
`;

export const SidebarTitle = styled.h3`
  background-color: ${({ theme }) => theme.colors.cardinal };
  color: ${({ theme }) => theme.colors.white };
  padding: 1.5rem;
  margin: 0;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SidebarSubtitle = styled.h4`
  background-color: ${({ theme }) => theme.colors.cardinal };
  color: ${({ theme }) => theme.colors.white };
  padding: 0 1.5rem 1.5rem;
  margin: 0;
`;

export const SidebarItem = styled(Link)`
  align-items: center;
  background-color: ${({ theme, active }) => active ? theme.colors.cardinal : theme.colors.white};
  color: ${({ theme, active }) => active ? theme.colors.white : theme.colors.cardinal};
  display: flex;
  min-height: 50px;
  padding: 1.25rem;
  position: relative;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme, active }) => darken(0.07, active ? theme.colors.cardinal : theme.colors.white)};
  }
`;

export const SidebarItemIcon = styled.div`
  display: inline-block;
  width: 24px;
`;

export const SidebarItemText = styled.span`
  font-weight: bold;
  padding-left: 1.25rem;
`;

export const Main = styled.div`
  margin-bottom: 2.5rem;
  position: relative;
  min-width: 0;
`;

export const SidebarProgressIcon = styled.div`
  /* position: absolute;
  right: 1.25rem; */
  margin-left: auto;
`;
