import React from "react";
import moment from "moment";
import { firestore } from "../../firebase";
import { Wrap, Inner, Card } from "../styled/Dashboard.styled";

class UnmarkedExams extends React.Component {
  state = {
    examAttempts: [],
  };

  async componentDidMount() {
    const { docs } = await firestore()
      .collection("exam-attempts")
      .where("mark", "==", null)
      .where("finishTime", "<=", firestore.Timestamp.now())
      .orderBy("finishTime", "asc")
      .get();

    const examAttempts = docs
      .map(d => ({
        id: d.id,
        ...d.data()
      }));

    this.setState({ examAttempts });
  }

  render() {
    const { examAttempts } = this.state;
    return (
      <Wrap>
        <Inner>
          {examAttempts.map(attempt => (
            <Card
              key={attempt.id}
              to={`/admin/exam-feedback/${attempt.id}`}
              height="auto"
            >
              <p>Exam Attempt: {attempt.id}</p>
              <p>Submitted at: {moment(attempt.finishTime.toMillis()).format('Do MMM YYYY HH:mm:ss')}</p>
            </Card>
          ))}
        </Inner>
      </Wrap>
    )
  }
}

export default UnmarkedExams;
