import styled from "styled-components";

export const Wrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.b}px) {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const EditorOuter = styled.div`
  height: 50%;
`;

export const ConsoleOuter = styled.div`
  height: 50%;
  background-color: #000;
`;
