import { Link } from "react-router-dom";
import styled from "styled-components";
import { Form as FormikForm, Field as FormikField } from "formik";

export const Wrap = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.whiteSmoke};
  justify-content: center;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.a}px) {
    align-items: center;
    padding: 5rem 0;
  }
`;

export const Inner = styled.div`
  min-width: 320px;
  max-width: 400px;
  width: 100%;
`;

export const Form = styled(FormikForm)`
  padding: 2rem 2.5rem;
  background-color: white;
`;

export const FormGroup = styled.div`
  margin-bottom: 2rem;
`;

export const Label = styled.label`
  display: block;
  font-weight: bold;
  font-size: 1.4rem;
  margin: 0 0 0.5rem;
`;

export const Input = styled(FormikField)`
  border: 0;
  font-family: inherit;
  font-size: inherit;
  border-bottom: 2px solid ${({ theme }) => theme.colors.whiteSmoke};
  padding: 1rem 0;
  display: block;
  width: 100%;
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: ${({ theme }) => theme.colors.cardinal};
  }
`;

export const Error = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.orangeSoda};
`;

export const Divider = styled.div`
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0;
  margin: 3rem 0 3rem;
  color: #000;
  opacity: 0.25;

  span {
    background-color: #fff;
    padding: 0 1rem;
  }
`;

export const FormLink = styled(Link)`
  display: block;
  text-align: center;
  margin: 1.5rem 0 3rem;
  color: inherit;
  font-family: 1.4rem;

  &:hover {
    text-decoration: none;
  }
`;
