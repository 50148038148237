import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { createAccount as _createAccount } from "../redux/auth/auth.slice";
import {
  Wrap,
  Inner,
  Form,
  FormGroup,
  Label,
  Input,
  Error
} from "./styled/Login.styled";
import { Title } from "./styled/Title.styled";
import { Button } from "./styled/Button.styled";
import {
  CheckboxWrap,
  Checkbox,
  CheckboxLabel
} from "./styled/Checkbox.styled";

const CreateAccountSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("Required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9@$=!:.#%]+$)/,
      "Password must contain at least one number, one uppercase letter and one lowercase letter"
    )
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required")
});

class CreateAccount extends Component {
  get applicationId() {
    const params = new URLSearchParams(this.props.location.search);
    if (params.has('applicationId')) return params.get('applicationId');
    return null;
  }

  handleSubmit = values => {
    const { createAccount } = this.props;

    createAccount({
      ...values,
      applicationId: this.applicationId,
    });
  };

  render() {
    const { user } = this.props;

    if (user) {
      return <Redirect to="/" />;
    }

    return (
      <Wrap>
        <Inner>
          <Title>Create Account</Title>
          <Formik
            initialValues={{ email: "", password: "", confirmPassword: "" }}
            validationSchema={CreateAccountSchema}
            onSubmit={this.handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <FormGroup>
                  <Label>Email</Label>
                  <Input name="email" type="email" autoFocus />
                  {errors.email && touched.email ? (
                    <Error>{errors.email}</Error>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <Input name="password" type="password" />
                  {errors.password && touched.password ? (
                    <Error>{errors.password}</Error>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>Confirm Password</Label>
                  <Input name="confirmPassword" type="password" />
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <Error>{errors.confirmPassword}</Error>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <CheckboxWrap>
                    <Checkbox id="receive-marketing" type="checkbox" />
                    <CheckboxLabel htmlFor="receive-marketing">
                      If you would like to hear about the latest news and offers
                      from Manchester Codes, please tick this box to join our
                      mailing list
                    </CheckboxLabel>
                  </CheckboxWrap>
                </FormGroup>
                <Button type="submit" stretch color="middleBlue">
                  Create Account
                </Button>
              </Form>
            )}
          </Formik>
        </Inner>
      </Wrap>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

const mapDispatchToProps = {
  createAccount: _createAccount
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccount);
