import { createSlice } from "redux-starter-kit";

const adminSlice = createSlice({
  slice: "admin",
  initialState: {
    loading: true,
    authorised: false
  },
  reducers: {
    checkIsAdmin() {},
    setAuthorised(state, { payload: authorised }) {
      state.authorised = authorised;
      state.loading = false;
    }
  }
});

export const { actions, reducer } = adminSlice;
export const { checkIsAdmin, setAuthorised } = actions;
export default reducer;
