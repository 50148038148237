import React from 'react';
import arrayMove from 'array-move';
import { toast } from 'react-toastify'
import { firestore } from '../../firebase';
import { Card } from "../styled/Dashboard.styled";
import { Wrap, Inner, Box, List, ListItem, Controls, ChapterBox, ChapterName, ExpandIcon, ExpandPanel, PaddedContainer } from "../styled/AdminChapters.styled";
import { Title } from "../styled/Title.styled";
import { Label, Input } from '../styled/Form.styled';
import { IconButton, Button } from "../styled/Button.styled";
import Modal from '../Modal';
import PageEditor from './PageEditor';
import Delete from "../icons/Delete";
import ChevronUp from "../icons/ChevronUp";
import ChevronDown from "../icons/ChevronDown";
import Edit from '../icons/Edit';
import axios from 'axios';
import { auth } from '../../firebase';


const newPage = () => ({
  id: '',
  title: '',
  content: '',
  quiz: [],
  video: {},
  icon: '',
});

const NEW_CHAPTER_ID = 'NEW_CHAPTER';

const getYoutubeId = (url) => {
  const regex = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
  const match = url.match(regex);
  return match && match[1].length > 0 ? match[1] : false;
};


class ManageChapters extends React.Component {
  state = {
    loaded: false,
    selectedChapter: '',
    chapters: [],
    pageModal: {
      open: false,
      page: newPage(),
    },
  };

  async componentDidMount() {
    try {
      const chapters = await this.fetchChapters();

      const query = new URLSearchParams(this.props.location.search);
      let selectedChapter = '';

      if (query.has('id')) {
        selectedChapter = query.get('id');
      }

      this.setState({
        chapters,
        selectedChapter
      }, async () => {
        if (this.state.selectedChapter !== '') {
          const pages = await this.fetchChapterPages(this.state.selectedChapter);
          this.setState((state) => ({
            chapters: state.chapters.map((chapter) => (
              chapter.id === state.selectedChapter ? { ...chapter, pages } : chapter
            ))
          }));
        }
      });
    } catch (error) {
      await toast.error(error.message);
    }

    this.setState({ loaded: true });
  }

  async fetchChapters() {
    try {
      const { docs } = await firestore().collection('chapters')
        .orderBy('name', 'asc')
        .get();

      const chapters = docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return chapters;
    } catch (error) {
      console.error(error);
      await toast.error('Error fetching chapters - please check the developer console');
    }
  }

  async fetchChapterPages(id) {
    const { chapters } = this.state;
    const chapter = chapters.find((c) => c.id === id);

    const docs = await Promise.all(chapter.pages.map((page) => page.get()));

    return docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  }

  handleChapterSelect(id) {
    const { history, location } = this.props;
    const { selectedChapter } = this.state;

    history.replace({
      pathname: location.pathname,
      search: [selectedChapter, NEW_CHAPTER_ID].includes(id) ? '' : `id=${id}`,
    });

    this.setState((state) => ({
      selectedChapter: state.selectedChapter === id ? '' : id,
    }), async () => {
      if (this.state.selectedChapter !== '') {
        const chapter = this.state.chapters.find((chapter) => (
          chapter.id === this.state.selectedChapter
        ));

        // if we've not fetched the document data before
        if (chapter.pages.some((page) => page.firestore)) {
          const pages = await this.fetchChapterPages(this.state.selectedChapter);
          this.setState((state) => ({
            chapters: state.chapters.map((chapter) => (
              chapter.id === state.selectedChapter ? { ...chapter, pages } : chapter
            ))
          }));
        }
      }
    });
  };

  handleChapterInputChange = (event) => {
    const { value, name } = event.target;

    this.setState((state) => ({
      chapters: state.chapters.map((chapter) => (chapter.id === state.selectedChapter
        ? { ...chapter, [name]: value }
        : chapter
      ))
    }));
  };

  handlePageInputChange = (event) => {
    const { value, name } = event.target;

    this.setState((state) => ({
      pageModal: {
        ...state.pageModal,
        page: {
          ...state.pageModal.page,
          [name]: value,
        },
      },
    }));
  }

  handleQuizAddQuestion = () => {
    this.setState((state) => ({
      pageModal: {
        ...state.pageModal,
        page: {
          ...state.pageModal.page,
          quiz: [
            ...state.pageModal.page.quiz,
            {
              question: '',
              answers: [],
              correctAnswers: [],
              success: '',
              hint: ''
            }
          ]
        }
      }
    }));
  }

  handleQuizRemoveQuestion = (questionIndex) => {
    this.setState((state) => ({
      pageModal: {
        ...state.pageModal,
        page: {
          ...state.pageModal.page,
          quiz: state.pageModal.page.quiz.filter((question, index) => index !== questionIndex),
        }
      }
    }));
  }

  handleQuizUpdateQuestion = (questionIndex, value) => {
    const newQuestions = this.state.pageModal.page.quiz.map((question, index) => {
      if (index !== questionIndex) {
        return question;
      }

      return {
        ...question,
        question: value
      }
    });

    this.setState((state) => ({
      pageModal: {
        ...state.pageModal,
        page: {
          ...state.pageModal.page,
          quiz: newQuestions,
        }
      }
    }));
  }

  handleQuizAddAnswer = (questionIndex) => {
    const { pageModal } = this.state;

    const newQuestions = pageModal.page.quiz.map((question, index) => ({
      ...question,
      answers: index === questionIndex ? question.answers.concat('') : question.answers,
    }));

    this.setState(({ pageModal: pageModalState }) => ({
      pageModal: {
        ...pageModalState,
        page: {
          ...pageModalState.page,
          quiz: newQuestions,
        }
      }
    }));
  } 

  handleQuizRemoveAnswer = (questionIndex, answerIndex) => {
    const { pageModal } = this.state;

    const newQuestions = pageModal.page.quiz.map((question, index) => ({
      ...question,
      answers: index === questionIndex ? question.answers.filter((answer, aIndex) => {
        return aIndex !== answerIndex
      }) : question.answers,
    }));

    this.setState(({ pageModal: pageModalState }) => ({
      pageModal: {
        ...pageModalState,
        page: {
          ...pageModalState.page,
          quiz: newQuestions,
        }
      }
    }));
  }

  handleQuizUpdateAnswer = (questionIndex, answerIndex, value) => {
    const { pageModal } = this.state;

    const newQuestions = pageModal.page.quiz.map((question, index) => {
      if (questionIndex !== index) {
        return question;
      }

      const newAnswers = question.answers.map((answer, aIndex) => {
        if (aIndex !== answerIndex) {
          return answer;
        }

        return value;
      })

      return {
        ...question,
        answers: newAnswers,
      }
    });

    this.setState(({ pageModal: pageModalState }) => ({
      pageModal: {
        ...pageModalState,
        page: {
          ...pageModalState.page,
          quiz: newQuestions,
        }
      }
    }));
  }

  handleQuizToggleCorrectAnswer = (questionIndex, answerIndex) => {
    const { pageModal } = this.state;

    const newQuestions = pageModal.page.quiz.map((question, index) => {
      if (index !== questionIndex) {
        return question;
      }

      const correctAnswers = question.correctAnswers.includes(answerIndex) 
        ? question.correctAnswers.filter((correctAnswer, correctAnswerIndex) => correctAnswerIndex !== answerIndex) 
        : [...question.correctAnswers, answerIndex]

      return {
        ...question,
        correctAnswers,
      }
    });

    this.setState(({ pageModal: pageModalState }) => ({
      pageModal: {
        ...pageModalState,
        page: {
          ...pageModalState.page,
          quiz: newQuestions,
        }
      }
    }));
  }

  handlePageUp = (i) => {
    this.setState((state) => ({
      chapters: state.chapters.map((chapter) => (
        chapter.id === state.selectedChapter
          ? {
            ...chapter,
            pages: arrayMove(chapter.pages, i, i - 1),
          }
          : chapter
      ))
    }));
  };

  handlePageDown = (i) => {
    this.setState((state) => ({
      chapters: state.chapters.map((chapter) => (
        chapter.id === state.selectedChapter
          ? {
            ...chapter,
            pages: arrayMove(chapter.pages, i, i + 1),
          }
          : chapter
      ))
    }));
  };

  async handlePageDeleteClick(id) {
    try {
      const confirmDelete = window.confirm('This will permanently delete the page from the database. Are you sure?');

      if (confirmDelete) {
        await firestore().collection('pages').doc(id).delete();

        await firestore().collection('chapters').doc(this.state.selectedChapter).update({
          pages: this.state.chapters.find((chapter) => chapter.id === this.state.selectedChapter).pages
            .filter((page) => page.id !== id)
            .map((page) => firestore().doc(`/pages/${page.id}`))
        });

        this.setState((state) => ({
          chapters: state.chapters.map((chapter) => (chapter.id === state.selectedChapter
            ? { ...chapter, pages: chapter.pages.filter((page) => page.id !== id) }
            : chapter
          )),
        }))

        await toast.success('Page deleted!');
      }
    } catch (error) {
      console.error(error);
      await toast.error('Unable to delete the page');
    }
  }

  handlePageAddClick = () => {
    this.setState({
      pageModal: {
        open: true,
        page: newPage(),
      },
    });
  }

  handlePageEditClick = (id) => {
    const getPage = state => state.chapters.find((chapter) => chapter.id === state.selectedChapter).pages.find((page) => page.id === id);

    this.setState((state) => ({
      pageModal: {
        open: true,
        page: {
          ...getPage(state),
          quiz: getPage(state).quiz ? getPage(state).quiz : [],  // LEGACY: Old page records don't have quiz arrays
          video: getPage(state).video ? getPage(state).video : {}
        },
      },
    }));
  }

  handlePageModalClose = () => {
    this.setState({
      pageModal: {
        open: false,
        page: newPage(),
      },
    });
  }

  handlePageModalSubmit = async () => {
    try {
      // if it's a new page (no id)
      const { id, ...page } = this.state.pageModal.page;

      if (page.videoUrl){
        const idToken = await auth().currentUser.getIdToken(true);

        const videoId = getYoutubeId(page.videoUrl);
  
        if(!videoId){
          toast.error(`Please check the URL is a valid YouTube link: ${page.videoUrl}`);
          return;
        }
  
        const {data} = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/video?videoId=${videoId}`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            }
          });
        
        page.video = {
          url: page.videoUrl,
          id: videoId,
          title: data.snippet.title,
          description: data.snippet.description,
          publishedAt: data.snippet.publishedAt,
          duration: data.contentDetails.duration,
          channel: {
            id: data.snippet.channelId,
            title: data.channel.title,
            thumbnails: {
              default: data.channel.thumbnails.default
            }
          }
         }
      }else{
        page.video = null;
      }
      

      if (id === '') {
        // submit it to pages collection,
        const doc = await firestore().collection('pages').add(page);

        // add page reference in chapter collection
        await firestore().collection('chapters')
          .doc(this.state.selectedChapter)
          .update({
            pages: (
              this.state.chapters.find((chapter) => chapter.id === this.state.selectedChapter).pages
                // convert the page ids back to doc references
                .map((pageId) => pageId.id ? firestore().doc(`/pages/${pageId.id}`) : firestore().doc(`/pages/${pageId}`))
                // add the new doc reference
                .concat(firestore().doc(`/pages/${doc.id}`))
            ),
          });

        this.setState((state) => ({
          // append the modal data in to the chapter state
          chapters: state.chapters.map((chapter) => (
            chapter.id === state.selectedChapter
              ? { ...chapter, pages: chapter.pages.concat({ ...state.pageModal.page, id: doc.id, })}
              : chapter
          )),
          // reset the modal state
          pageModal: {
            open: false,
            page: newPage(),
          },
        }));

        await toast.success('New page added successfully');
      } else {
        // if it's an existing page
          // update it in firebase
        await firestore().collection('pages').doc(id).update(page);

        this.setState((state) => ({
          // copy the modal data into the chapter in state
          chapters: state.chapters.map((chapter) => (
            chapter.id === state.selectedChapter
              ? {
                ...chapter,
                pages: chapter.pages.map((page) => page.id === id ? state.pageModal.page : page),
              }
              : chapter
          )),
          // reset the modal state
          pageModal: {
            open: false,
            page: newPage(),
          },
        }));

        await toast.success('Page updated successfully');
      }
    } catch (error) {
      console.error(error);
      await toast.error('Unable to save the page. See developer console for details.');
    }
  }

  handleAddChapterClick = () => {
    const { history, location } = this.props;

    history.replace({
      pathname: location.pathname,
      search: '',
    });

    this.setState((state) => ({
      selectedChapter: NEW_CHAPTER_ID,
      chapters: (
        state.chapters.some((chapter) => chapter.id === NEW_CHAPTER_ID)
          ? state.chapters
          : (
            [
              {
                id: NEW_CHAPTER_ID,
                name: 'New Chapter',
                slug: 'new-chapter',
                pages: [],
              },
              ...state.chapters,
            ]
          )
      ),
    }));
  };

  handleDeleteChapterClick = async () => {
    const { selectedChapter, chapters } = this.state;
    const chapter = chapters.find((c) => c.id === selectedChapter);

    const isNewChapter = selectedChapter === NEW_CHAPTER_ID;

    const confirmation = window.confirm(
      isNewChapter
        ? 'Are you sure?'
        : 'This will delete the chapter and all pages from the database permanently. Are you sure?'
    );

    if (confirmation) {
      if (!isNewChapter) {
        await Promise.all([
          firestore().collection('chapters').doc(selectedChapter).delete(),
          ...chapter.pages.map((page) => firestore().collection('pages').doc(page.id).delete()),
        ]);
      }

      this.setState((state) => ({
        selectedChapter: '',
        chapters: state.chapters.filter((c) => c.id !== state.selectedChapter),
      }), () => {
          const { history, location } = this.props;

          history.replace({
            pathname: location.pathname,
            search: '',
          });
      });
    }
  };

  handleChapterSubmit = async () => {
    try {
      const { selectedChapter, chapters } = this.state;
      const chapter = chapters.find((c) => c.id === selectedChapter);

      if (selectedChapter === NEW_CHAPTER_ID) {
        const doc = await firestore().collection('chapters').add({
          name: chapter.name,
          slug: chapter.slug,
          pages: [],
        });

        await toast.success('Chapter added successfully');

        this.setState((state) => ({
          selectedChapter: doc.id,
          chapters: state.chapters.map((c) => c.id === NEW_CHAPTER_ID
            ? { ...c, id: doc.id }
            : c
          )
        }), () => {
          const { location, history } = this.props;

          history.replace({
            pathname: location.pathname,
            search: `id=${doc.id}`,
          });
        });
      } else {
        await firestore().collection('chapters').doc(selectedChapter).update({
          name: chapter.name,
          slug: chapter.slug,
          pages: chapter.pages.map(page => firestore().collection('pages').doc(page.id)),
        });

        await toast.success('Chapter updated successfully');
      }
    } catch (error) {
      console.error(error);
      await toast.error('Unable to save chapter. See developer console for more information.');
    }
  }

  render () {
    const { loaded, chapters, selectedChapter, pageModal } = this.state;

    if (!loaded) {
      return <Card to="#" onClick={(e) => e.preventDefault()} loading>Loading...</Card>;
    }

    return (
      <Wrap>
        <Inner>
          <Title>Chapters</Title>
          <Box>
            <PaddedContainer>
              <Button onClick={this.handleAddChapterClick}>Add Chapter</Button>
            </PaddedContainer>
            <List>
              {chapters.map((chapter) => (
                <ChapterBox key={chapter.id}>
                  <ChapterName>
                    {chapter.name}{chapter.id === NEW_CHAPTER_ID && ' (unsaved)'}
                    <ExpandIcon
                      selected={selectedChapter === chapter.id}
                      onClick={() => this.handleChapterSelect(chapter.id)}
                    />
                  </ChapterName>
                  <ExpandPanel open={selectedChapter === chapter.id}>
                    <PaddedContainer>
                      <PaddedContainer>
                        <Label>Name:</Label>
                        <Input
                          type="text"
                          name="name"
                          value={chapter.name}
                          onChange={this.handleChapterInputChange}
                        />
                      </PaddedContainer>
                      <PaddedContainer>
                        <Label>Slug:</Label>
                        <Input
                          type="text"
                          name="slug"
                          value={chapter.slug}
                          onChange={this.handleChapterInputChange}
                        />
                      </PaddedContainer>
                      <ChapterName>Pages:</ChapterName>
                      <List>
                        {chapter.pages.map((page, i, pages) => (
                          <ListItem key={page.id}>
                            {page.title}
                            <Controls>
                              {i === 0 ? null : (
                                <IconButton
                                  title="Move Up"
                                  onClick={() => this.handlePageUp(i)}
                                >
                                  <ChevronUp />
                                </IconButton>
                              )}
                              {i === pages.length - 1 ? null : (
                                <IconButton
                                  title="Move Down"
                                  onClick={() => this.handlePageDown(i)}
                                >
                                  <ChevronDown />
                                </IconButton>
                              )}
                              <IconButton
                                title="Edit"
                                onClick={() => this.handlePageEditClick(page.id)}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                title="Remove"
                                onClick={() => this.handlePageDeleteClick(page.id)}
                              >
                                <Delete />
                              </IconButton>
                            </Controls>
                          </ListItem>
                        ))}
                      </List>
                    </PaddedContainer>
                    <PaddedContainer>
                      <Button
                        disabled={chapter.id === NEW_CHAPTER_ID}
                        onClick={this.handlePageAddClick}
                      >
                        Add Page
                      </Button>
                      <Button
                        color="seaGreen"
                        onClick={this.handleChapterSubmit}
                      >
                        Save Chapter
                      </Button>
                      <Button
                        color="orangeSoda"
                        onClick={this.handleDeleteChapterClick}
                      >
                        Delete Chapter
                      </Button>
                    </PaddedContainer>
                  </ExpandPanel>
                </ChapterBox>
              ))}
            </List>
          </Box>
        </Inner>
        <Modal
          open={pageModal.open}
          onEscapePress={() => {}}
          onOverlayClick={() => {}}
        >
          {() => (
            <PageEditor
              {...pageModal.page}
              onFieldChange={this.handlePageInputChange}
              onSubmit={this.handlePageModalSubmit}
              onClose={this.handlePageModalClose}
              onQuizAddQuestion={this.handleQuizAddQuestion}
              onQuizRemoveQuestion={this.handleQuizRemoveQuestion}
              onQuizUpdateQuestion={this.handleQuizUpdateQuestion}
              onQuizAddAnswer={this.handleQuizAddAnswer}
              onQuizQuizRemoveAnswer={this.handleQuizRemoveAnswer}
              onQuizUpdateAnswer={this.handleQuizUpdateAnswer}
              onQuizToggleCorrectAnswer={this.handleQuizToggleCorrectAnswer}
            />
          )}
        </Modal>
      </Wrap>
    );
  }
}

export default ManageChapters;
