import React from "react";
import styled from "styled-components";
import { Label } from "./Form.styled"

const Wrap = styled.div`
  position: relative;

  &:after {
    content: "▾";
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 3rem;
  }
`;

const SelectControl = styled.select`
  appearance: none;
  border-radius: 0;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  border-bottom: 2px solid ${({ theme }) => theme.colors.whiteSmoke};
  padding: 1rem 0;
  display: block;
  width: 100%;
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: ${({ theme }) => theme.colors.cardinal};
  }
`;

const Select = ({ children, label = '', ...props }) => (
  <>
    {label && <Label>{label}</Label>}
    <Wrap>
      <SelectControl {...props}>{children}</SelectControl>
    </Wrap>
  </>
);

export default Select;
