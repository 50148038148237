import styled from "styled-components";

export const Wrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.a}px) {
    padding: 5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const Inner = styled.div`
  min-width: 320px;
  max-width: 900px;
  width: 100%;
`;

export const Paper = styled.div`
  padding: 2rem 2.5rem;
  background-color: white;
`;

export const Spacer = styled.div`
  margin-top: 2.5rem;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;
