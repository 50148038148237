import React from "react";
import { Wrap, EditorOuter, ConsoleOuter } from "./styled/Ide.styled";
import Editor from "./Editor";
import Console from "./Console";

const Ide = () => (
  <Wrap>
    <EditorOuter>
      <Editor />
    </EditorOuter>
    <ConsoleOuter>
      <Console />
    </ConsoleOuter>
  </Wrap>
);

export default Ide;
