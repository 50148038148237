import { Link } from "react-router-dom";
import styled from "styled-components";
import { darken } from "polished";

export const Wrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.a}px) {
    padding: 5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const Inner = styled.div`
  min-width: 320px;
  max-width: 900px;
  width: 100%;
`;

export const Card = styled(Link)`
  ${({ theme, loading, height = '100px', color = "middleBlue" }) => `
    display: block;
    padding: 2.5rem;
    height: ${height};
    color: white;
    font-size: 18px;
    font-weight: bold;
    background-color: ${theme.colors[color]};
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
    position: relative;
    margin-bottom: 1px;

    &:hover {
      background-color: ${darken(0.1, theme.colors[color])};
    }

    ${
      loading
        ? `
      background-color: #ccc;
      cursor: default;

      &:hover {
        background-color: #ccc;
      }
    `
        : ``
    }
  `}
`;

Card.shouldForwardProp = (prop)=> !["loading"].includes(prop);

export const CardType = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  color: white;
  opacity: 0.5;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.4rem;
`;

export const Block = styled.div`
  margin-bottom: 2.5rem;

  &:last-of-type {
    margin-bottom: auto;
  }
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.25rem;
  padding: 1.25rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.a}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const GridCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.25rem;
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'initial'};
`;
