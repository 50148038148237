import React from 'react';
import Icon from './Icon';

const CheckCircleOutline = (props) => (
  <Icon
    {...props}
    path="M512 854q140 0 241-101t101-241-101-241-241-101-241 101-101 241 101 241 241 101zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125zM708 324l60 60-342 342-212-214 60-60 152 152z"
  />
);

export default CheckCircleOutline;
