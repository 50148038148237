import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify'
import firestore from '../firebase';
import { SidebarLayout, Sidebar, SidebarTitle, SidebarSubtitle, SidebarList, SidebarItem, SidebarItemText, SidebarItemIcon, Main, SidebarProgressIcon } from "./styled/Sidebar.styled";
import { Title } from "./styled/Title.styled";
import { Card } from "./styled/Dashboard.styled";
import * as Icons from './icons';
import { Buttons } from './styled/Guide.styled';
import { Button } from './styled/Button.styled';
import Page from './page';

class Chapter extends React.Component {
  state = {
    chapter: null,
    loaded: false,
    moduleName: '',
    progress: {
      pages: [],
    },
  };

  contentRef = React.createRef();

  async componentDidMount() {
    const { chapterSlug, moduleSlug } = this.props.match.params;
    const { history } = this.props;
    try {
      const chapter = await this.fetchChapter(chapterSlug);

      // LEGACY: we used to use just the chapter in the url schema, not module and chapter
      // if we don't have the module slug in the url we need to figure out which module it is
      // chapters only belong to one module so we can use the chapter to get this reliably
      // we also redirect to the new url schema once we know the module slug

      let moduleName = '';
      let redirect = '';

      if (moduleSlug) {
        moduleName = await this.fetchModuleNameFromSlug(moduleSlug);
      } else {
        const { name, slug } = await this.fetchModuleFromChapter(chapter.id);
        moduleName = name;
        redirect = slug;
      }

      const progress = await this.fetchStudentProgress(chapter.id);

      this.setState({
        chapter,
        loaded: true,
        moduleName,
        progress,
      }, () => {
        if (!moduleSlug) {
          // redirect to the new style url
          history.replace(`/module/${redirect}/chapter/${chapterSlug}`);
        }
      });
    } catch (error) {
      await toast.error(error.message);
    }
  }

  componentDidUpdate(prevProps) {
    const prevParams = new URLSearchParams(prevProps.location.search);
    if (this.contentRef.current && prevParams.get('pageId') !== this.pageId) {
      const topOfContent = window.pageYOffset + this.contentRef.current.getBoundingClientRect().y
      if (window.scrollY > topOfContent) {
        window.scrollTo(0, topOfContent - 12.5)
      }
    }
  }

  async fetchChapter(slug) {
    let chapters = [];

    try {
      const { docs } = await firestore().collection('chapters').where('slug', '==', slug).get();
      chapters = docs;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to load chapter.')
    }

    if (!chapters.length) {
      throw new Error('Chapter not found.');
    }

    const [doc] = chapters;
    const chapter = doc.data();

    try {
      const pages = await Promise.all(chapter.pages.map(page => page.get()));

      return {
        ...chapter,
        id: doc.id,
        pages: pages.map(page => ({ ...page.data(), id: page.id })),
      };
    } catch (error) {
      console.error(error);
      throw new Error('Unable to load chapter.')
    }
  }

  async fetchModuleNameFromSlug(slug) {
    try {
      const { docs } = await firestore().collection('modules').where('slug', '==', slug).get();
      if (!docs.length) {
        throw new Error('Module not found.');
      }
      const [doc] = docs;
      const mod = doc.data();
      return mod.name;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to load module.')
    }
  }

  async fetchModuleFromChapter(chapterId) {
    try {
      const { docs } = await firestore().collection('modules').where('chapters', 'array-contains', chapterId).get();
      if (!docs.length) {
        throw new Error('Module not found.');
      }
      const [doc] = docs;
      const mod = doc.data();
      return mod;
    } catch (error) {
      console.error(error);
      throw new Error('Unable to load module.')
    }
  }

  async fetchStudentProgress(chapterId) {
    const { user } = this.props;
    try {
      const { docs } = await firestore().collection('progressions')
        .where('chapterId', '==', chapterId)
        .where('userId', '==', user.uid)
        .get();

      if (!docs.length) {
        return {
          chapterId,
          userId: user.uid,
          pages: [],
        };
      }

      const [doc] = docs;
      return {
        ...doc.data(),
        id: doc.id,
      };
    } catch (error) {
      console.error('[ERROR]', error);
      return {
        chapterId,
        userId: user.uid,
        pages: [],
      };
    }
  }

  async addProgress(pageId) {
    if (!this.state.progress.pages.some((page) => page.id === pageId)) {
      this.setState((state) => ({
        progress: {
          ...state.progress,
          pages: state.progress.pages.concat({
            id: pageId,
            updatedAt: firestore.Timestamp.now(),
          }),
        },
      }), async () => {
        try {
          if (this.state.progress.id) {
            await firestore().collection('progressions').doc(this.state.progress.id).update(this.state.progress);
          } else {
            await firestore().collection('progressions').add(this.state.progress);
          }
        } catch (error) {
          console.error('Unable to save progress', error);
        }
      });
    }
  }

  get pageId() {
    const params = new URLSearchParams(this.props.location.search);
    if (params.has('pageId')) return params.get('pageId');
    return null;
  }

  get pageIndex() {
    const { chapter } = this.state;
    return chapter.pages.findIndex(page => page.id === this.pageId);
  }

  get page() {
    const { chapter } = this.state;
    return chapter.pages.find((page => page.id === this.pageId)) || null;
  }

  handlePageBack = () => {
    const { history, location } = this.props;
    const { chapter } = this.state;
    history.push(`${location.pathname}?pageId=${chapter.pages[this.pageIndex - 1].id}`);
  }

  handlePageForward = async () => {
    const { history, location, match } = this.props;
    const { chapter } = this.state;

    await this.addProgress(this.pageId);

    if (this.pageIndex < chapter.pages.length - 1) {
      history.push(`${location.pathname}?pageId=${chapter.pages[this.pageIndex + 1].id}`);
    } else {
      history.push(`/module/${match.params.moduleSlug}`);
    }
  }

  render() {
    const { chapter, loaded, moduleName } = this.state;
    const { location, match: { params: { moduleSlug } } }= this.props;
    const { CheckCircle } = Icons;

    if (!loaded) {
      return <Card to="#" onClick={(e) => e.preventDefault()} loading>Loading...</Card>;
    }

    if (!this.pageId && chapter.pages.length) {
      const [page] = chapter.pages;
      return <Redirect to={`${location.pathname}?pageId=${page.id}`} />
    }

    return (
      <SidebarLayout>
        <div>
          <Sidebar>
            <SidebarTitle>
              <Link to={`/module/${moduleSlug}`}>{moduleName}</Link>
            </SidebarTitle>
            <SidebarSubtitle>
              {chapter.name}
            </SidebarSubtitle>
            <SidebarList>
            {chapter.pages.map((page) => {
              const Icon = Icons[page.icon || 'Document'];
              return (
                <SidebarItem
                  key={page.id}
                  active={page.id === this.pageId}
                  to={`${location.pathname}?pageId=${page.id}`}
                >
                  <SidebarItemIcon>
                    <Icon color={page.id === this.pageId ? 'white' : 'cardinal'} />
                  </SidebarItemIcon>
                  <SidebarItemText>{page.title}</SidebarItemText>
                  {
                    this.state.progress.pages.some((p) => p.id === page.id) ? (
                      <SidebarProgressIcon>
                        <CheckCircle color={page.id === this.pageId ? 'white' : 'cardinal'} />
                      </SidebarProgressIcon>
                    ) : null
                  }
                </SidebarItem>
              );
            })}
            </SidebarList>
          </Sidebar>
        </div>
        <Main ref={this.contentRef}>
          <Title block>{this.page.title}</Title>
          <Page page={this.page} />
          <Buttons>
            {(this.pageIndex) > 0 ? (
              <Button onClick={this.handlePageBack}>Previous Step</Button>
            ) : <div />}
            <Button onClick={this.handlePageForward}>
              {this.pageIndex < (chapter.pages.length - 1) ? 'Next Step' : 'Finish Chapter'}
            </Button>
          </Buttons>
        </Main>
      </SidebarLayout>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(Chapter);
