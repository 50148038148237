import { takeLatest, put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import uglify from "uglifyjs-browser";
import { updateOutput, setValid, setExecutedCode } from "../ide/ide.slice";
import { firestore } from "../../firebase";

function* runCode() {
  const {
    ide: { code, executedCode },
    course: { page },
    auth: { user }
  } = yield select();

  try {
    const output = [];

    const log = console.log;
    console.log = message => {
      output.push({ type: "log", value: JSON.stringify(message) });
      return;
    };

    try {
      output.push({
        type: "return",
        // eslint-disable-next-line no-eval
        value: JSON.stringify(eval(code)) || "undefined"
      });
    } catch (ex) {
      // eslint-disable-next-line no-eval
      output.push({ type: "log", value: `${ex.name}: ${ex.message}` });
    }

    console.log = log;

    yield put(setExecutedCode(code));
    yield put(updateOutput(output));

    if (page) {
      const minified = yield uglify.minify(code);
      const isCorrect = minified.code === page.solution.minifiedCode;

      if (code !== executedCode) {
        yield firestore()
          .collection("attempts")
          .add({
            page: firestore().doc(`pages/${page.id}`),
            userId: user.uid,
            code,
            minified: minified.code,
            isCorrect,
            timestamp: firestore.Timestamp.now()
          });
      }

      if (isCorrect) {
        yield toast.success("That's correct, well done!");
        yield put(setValid(true));
      } else {
        yield toast.error(
          "That isn't quite right - please try again. Check the solution if you aren't sure."
        );
        yield put(setValid(false));
      }
    }
  } catch (ex) {
    yield toast.error(ex.message);
  }
}

function* ideSaga() {
  yield takeLatest("ide/runCode", runCode);
}

export default ideSaga;
