import React from "react";
import { connect } from "react-redux";
import { Wrap, Toolbar, Title, Output, Line } from "./styled/Console.styled";

const Console = ({ output, exam }) => (
  <Wrap>
    <Toolbar>
      <Title>Console</Title>
    </Toolbar>
    <Output exam={exam}>
      {output.map(line => (
        <Line type={line.type}>{line.value}</Line>
      ))}
    </Output>
  </Wrap>
);

const mapStateToProps = ({ ide: { output } }) => ({
  output
});

export default connect(mapStateToProps)(Console);
