import React from 'react';
import Icon from './Icon';

const Video = (props) => (
  <Icon
    {...props}
    path="M726 448l170-170v468l-170-170v150q0 18-13 30t-31 12h-512q-18 0-30-12t-12-30v-428q0-18 12-30t30-12h512q18 0 31 12t13 30v150z"
  />
);

export default Video;
