import { createSlice } from "redux-starter-kit";

const enrolmentSlice = createSlice({
  slice: "enrolment",
  initialState: {
  },
  reducers: {
  }
});

export const { actions, reducer } = enrolmentSlice;
export const {} = actions;
export default reducer;
