import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import qs from "qs";
import { doChangePassword as _doChangePassword } from "../redux/auth/auth.slice";
import {
  Wrap,
  Inner,
  Form,
  FormGroup,
  Label,
  Input,
  Error
} from "./styled/Login.styled";
import { Title } from "./styled/Title.styled";
import { Button } from "./styled/Button.styled";

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9@$=!:.#%]+$)/,
      "Password must contain at least one number, one special character, one uppercase letter and one lowercase letter"
    )
    .required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required")
});

class ChangePassword extends Component {
  handleChangePassword = ({ password }) => {
    const { doChangePassword, location } = this.props;
    const { oobCode } = qs.parse(location.search, { ignoreQueryPrefix: true });

    doChangePassword({ password, oobCode });
  };

  render() {
    const { user, submitted } = this.props;

    if (user || submitted) {
      return <Redirect to="/" />;
    }

    return (
      <Wrap>
        <Inner>
          <Title>Change Password</Title>
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={ChangePasswordSchema}
            onSubmit={this.handleChangePassword}
          >
            {({ errors, touched }) => (
              <Form>
                <p>Please choose a new password below.</p>
                <FormGroup>
                  <Label>Password</Label>
                  <Input name="password" type="password" />
                  {errors.password && touched.password ? (
                    <Error>{errors.password}</Error>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>Confirm Password</Label>
                  <Input name="confirmPassword" type="password" />
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <Error>{errors.confirmPassword}</Error>
                  ) : null}
                </FormGroup>
                <Button type="submit" stretch color="middleBlue">
                  Change Password
                </Button>
              </Form>
            )}
          </Formik>
        </Inner>
      </Wrap>
    );
  }
}

const mapStateToProps = ({ auth: { user, forms } }) => ({
  user,
  submitted: forms.changePassword.submitted
});

const mapDispatchToProps = {
  doChangePassword: _doChangePassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
