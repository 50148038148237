import * as AdminPages from '.';
import ExamFeedback from "../ExamFeedback";

// {
//   ...ReactRouter.Route props
//   showOnDashboard: boolean - show a link card on the admin dashboard
//   title: string - shown on the card above, and on the breadcrumb,
// }

export default [
  {
    component: AdminPages.AdminDashboard,
    exact: true,
    path: '/admin',
    showOnDashboard: false,
    title: 'Dashboard',
  },
  {
    component: AdminPages.ManageCourses,
    exact: true,
    path: '/admin/courses',
    showOnDashboard: true,
    title: 'Course Management',
  },
  {
    component: AdminPages.ManageModules,
    exact: true,
    path: '/admin/modules',
    showOnDashboard: true,
    title: 'Module Management',
  },
  {
    component: AdminPages.ManageChapters,
    exact: true,
    path: '/admin/chapters',
    showOnDashboard: true,
    title: 'Chapter Management'
  },
  {
    component: AdminPages.PageEditor,
    exact: true,
    path: '/admin/add-page',
    showOnDashboard: false,
    title: 'Add Page'
  },
  {
    component: AdminPages.PageEditor,
    exact: true,
    path: '/admin/edit-page/:pageId',
    showOnDashboard: false,
    title: 'Edit Page'
  },
  {
    component: AdminPages.UnmarkedExams,
    exact: true,
    path: '/admin/unmarked-exams',
    showOnDashboard: true,
    title: 'Unmarked Exams'
  },
  {
    component: ExamFeedback,
    exact: true,
    path: '/admin/exam-feedback/:attemptId',
    showOnDashboard: false,
    title: 'Exam Feedback',
  },
  {
    component: AdminPages.StudentDashboard,
    exact: true,
    path: '/admin/students',
    showOnDashboard: true,
    title: 'Student Dashboard',
  },
  { component: AdminPages.AdminUsers,
    exact: true,
    path: '/admin/users',
    showOnDashboard: true,
    title: 'Admin User Management',
  },
];
