import React from 'react';
import Icon from './Icon';

const Edit = (props) => (
  <Icon
    {...props}
    path="M884 300l-78 78-160-160 78-78q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM128 736l472-472 160 160-472 472h-160v-160z"
  />
);

export default Edit;
