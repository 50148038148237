import { createSlice } from "redux-starter-kit";

const examSlice = createSlice({
  slice: "exam",
  initialState: {
    exam: null,
    currentQuestion: null,
    status: "summary",
    answers: [],
    attemptId: null,
    disabled: true,
    previousExam: null,
    feedback: []
  },
  reducers: {
    getExam() {},
    setExam(state, { payload: exam }) {
      state.exam = exam;
    },
    setExamNoRequest(state, { payload: exam }) {
      state.exam = exam;
      state.feedback = exam.questions.map(question => ({
        comment: "",
        score: 0,
        questionId: question.id
      }));
    },
    setCurrentQuestion(state, { payload: currentQuestion }) {
      state.currentQuestion = currentQuestion;
    },
    startExam(state) {
      state.currentQuestion = state.exam.questions[0];
      state.status = "started";
    },
    doAttempt() {},
    nextQuestion() {},
    logAnswer(state, { payload: answer }) {
      const currentQuestion = state.exam.questions.find(
        question => question.id === answer.questionId
      );
      const currentQuestionIndex = state.exam.questions.indexOf(
        currentQuestion
      );

      if (state.answers[currentQuestionIndex]) {
        state.answers[currentQuestionIndex] = answer;
      } else {
        state.answers.push(answer);
      }
    },
    goForward(state) {
      const currentQuestion = state.exam.questions.find(
        question => question.id === state.currentQuestion.id
      );
      const currentQuestionIndex = state.exam.questions.indexOf(
        currentQuestion
      );

      state.currentQuestion = state.exam.questions[currentQuestionIndex + 1];
    },
    prevQuestion(state) {
      const currentQuestion = state.exam.questions.find(
        question => question.id === state.currentQuestion.id
      );
      const currentQuestionIndex = state.exam.questions.indexOf(
        currentQuestion
      );

      state.currentQuestion = state.exam.questions[currentQuestionIndex - 1];
    },
    completeExam() {},
    setAttemptId(state, { payload: attemptId }) {
      state.attemptId = attemptId;
    },
    updateStatus(state, { payload: status }) {
      state.status = status;
    },
    setDisabled(state, { payload: disabled }) {
      state.disabled = disabled;
    },
    setPreviousExam(state, { payload: previousExam }) {
      state.previousExam = previousExam;
      state.feedback = previousExam.feedback.length ? previousExam.feedback : state.feedback;
    },
    getPreviousExam() {},
    updateFeedback(
      state,
      {
        payload: { questionId, field, value }
      }
    ) {
      const question = state.feedback.find(
        singleFeedback => singleFeedback.questionId === questionId
      );
      question[field] = value;
    },
    leaveFeedback() {},
    setExamUser(state, { payload: user }) {
      state.user = user;
    }
  }
});

export const { actions, reducer } = examSlice;
export const {
  getExam,
  setExam,
  setQuestionSet,
  getQuestion,
  setCurrentQuestion,
  doAttempt,
  startExam,
  nextQuestion,
  logAnswer,
  prevQuestion,
  setAttemptId,
  completeExam,
  updateStatus,
  setDisabled,
  setPreviousExam,
  getPreviousExam,
  setExamNoRequest,
  updateFeedback,
  leaveFeedback,
  setExamUser,
  goForward
} = actions;
export default reducer;
