import { createSlice } from "redux-starter-kit";

const ideSlice = createSlice({
  slice: "ide",
  initialState: {
    code: "",
    output: [],
    valid: false,
    executedCode: ""
  },
  reducers: {
    updateCode(state, { payload: code }) {
      state.code = code;
    },
    runCode() {},
    updateOutput(state, { payload: output }) {
      state.output = output;
    },
    setValid(state, { payload: valid }) {
      state.valid = valid;
    },
    setExecutedCode(state, { payload: executedCode }) {
      state.executedCode = executedCode;
    }
  }
});

export const { actions, reducer } = ideSlice;
export const {
  updateCode,
  runCode,
  updateOutput,
  setValid,
  setExecutedCode
} = actions;
export default reducer;
