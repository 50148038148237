import React from "react";
import { connect } from "react-redux";
import {
  Wrap,
  Toolbar,
  Title,
  Actions,
  TextArea
} from "./styled/Editor.styled";
import { Button } from "./styled/Button.styled";
import {
  updateCode as _updateCode,
  runCode as _runCode
} from "../redux/ide/ide.slice";

import "codemirror/mode/javascript/javascript";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";

const Editor = ({ code, updateCode, runCode, initialCode, exam = false }) => (
  <Wrap>
    <Toolbar>
      <Title>Code Editor</Title>
      <Actions>
        {/* {!exam ? (
          <Fragment>
            <Button
              type="button"
              color="manatee"
              onClick={() => updateCode(initialCode)}
            >
              Reset
            </Button>
            <Button type="button" color="middleBlue" onClick={() => {}}>
              View Solution
            </Button>
          </Fragment>
        ) : null} */}
        <Button type="button" onClick={() => runCode()}>
          Run Code
        </Button>
      </Actions>
    </Toolbar>
    <TextArea
      exam={exam}
      value={code}
      options={{
        mode: "javascript",
        theme: "material",
        autofocus: true,
        lineNumbers: true
      }}
      onBeforeChange={(editor, data, value) => updateCode(value)}
    />
  </Wrap>
);

const mapStateToProps = ({ ide: { code } }) => ({
  code,
});

const mapDispatchToProps = {
  updateCode: _updateCode,
  runCode: _runCode
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Editor);
