import styled from "styled-components";
import { Controlled as CodeMirror } from "react-codemirror2";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const Toolbar = styled.div`
  background-color: ${({ theme }) => theme.colors.manatee};
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h2`
  margin: 0;
  color: white;
  font-size: 1.8rem;
  flex: 1;
  padding: 1rem 2.5rem;
`;

export const Actions = styled.div`
  display: flex;
`;

export const TextArea = styled(CodeMirror)`
  font-size: 18px;
  flex: 1;
  display: flex;
  overflow: auto;
  min-height: 200px;

  ${({ exam }) =>
    exam
      ? `
    min-height: 250px;
  `
      : ``}

  .CodeMirror {
    height: auto;
    flex: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.b}px) {
    min-height: unset;

    ${({ exam }) =>
      exam
        ? `
      min-height: 250px;
    `
        : ``}
  }
`;
