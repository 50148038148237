import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Wrap,
  LogoContainer,
  Logo,
  ButtonContainer,
  Button,
  Link
} from "./styled/Header.styled";
import { auth } from "../firebase";

const Header = ({ history, user, admin }) => (
  <Wrap>
    <LogoContainer>
      <a href="/">
        <Logo
          src="https://a.storyblok.com/f/79570/302x64/0779d29aca/mcr_codes_cmyk_trim.png"
          alt="Manchester Codes"
        />
      </a>
    </LogoContainer>
    <ButtonContainer>
      {admin.authorised ? (
        <Link to="/admin">Admin</Link>
      ) : null}
      {user ? (
          <Button
            onClick={async () => {
              await auth().signOut();
              history.push("/");
            }}
          >
            Logout
          </Button>
      ) : null}
    </ButtonContainer>
  </Wrap>
);

const mapStateToProps = ({ auth: { user }, admin }) => ({ user, admin });

export default withRouter(connect(mapStateToProps)(Header));
