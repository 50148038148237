import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Overlay = styled.div`
  z-index: 998;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  max-height: 100%;
  margin: 2.5rem;
  overflow-y: scroll;
  width: 100%;
`;

class Modal extends React.Component {
  componentDidMount() {
    window.addEventListener('keydown', this.handleEscapePress);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEscapePress);
  }

  handleEscapePress = (e) => {
    const { onEscapePress } = this.props;

    if (e.code === 'Escape') {
      onEscapePress(e);
    }
  };

  handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      const { onOverlayClick } = this.props;
      onOverlayClick(e);
    }
  };

  render() {
    const { children, open } = this.props;

    return open && (
      <Overlay onClick={this.handleOverlayClick}>
        <Content>
          {children()}
        </Content>
      </Overlay>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.func,
  open: PropTypes.bool,
  onOverlayClick: PropTypes.func,
  onEscapePress: PropTypes.func,
};

Modal.defaultProps = {
  children: () => null,
  open: false,
  onOverlayClick: () => { },
  onEscapePress: () => { },
};

export default Modal;
