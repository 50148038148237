import React, { Fragment } from "react";
import Markdown from "markdown-to-jsx";
import EmojiConverter from "emoji-js";
import { Code, YouTube } from "../guide-components";
import { MarkdownStyles } from "../styled/GHMarkdown.styled";
import Editor from "../Editor";
import Console from "../Console";
import { Spacer } from "../styled/Exam.styled";

const Question = ({ content }) => {
  const emojiConverter = new EmojiConverter();
  const formattedContent = emojiConverter.replace_colons(content || "");

  return (
    <Fragment>
      <MarkdownStyles>
        <Markdown
          options={{
            overrides: {
              pre: {
                component: Code
              },
              YouTube: {
                component: YouTube
              },
              a: {
                props: {
                  target: "_blank"
                }
              }
            }
          }}
        >
          {formattedContent || ""}
        </Markdown>
      </MarkdownStyles>
      <Spacer>
        <Editor exam />
        <Console exam />
      </Spacer>
    </Fragment>
  );
};

export default Question;
