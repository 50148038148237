import { takeLatest, put, call, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import axios from 'axios';
import { auth, firestore } from "../../firebase";
import {
  setUser,
  passwordResetRequestSent,
  passwordResetSuccessful
} from "./auth.slice";
import { flattenFirebaseUser } from "../../helpers/auth";

async function* createAccountSaga({ payload: { email, password, applicationId } }) {
  try {
    yield auth().createUserWithEmailAndPassword(email, password);

    const user = flattenFirebaseUser(auth().currentUser);

    if (applicationId) {
      await axios.patch(`${process.env.REACT_APP_APPLICATION_ENDPOINT}/${applicationId}/create-account`, { user: user.uid });
    }

    yield put(setUser(user));
    yield call(sendEmailVerificationSaga);
  } catch (ex) {
    yield toast.error(ex.message);
  }
}

function* sendEmailVerificationSaga() {
  yield auth().currentUser.sendEmailVerification();
}

function* doEmailVerificationSaga({ payload: oobCode }) {
  try {
    yield auth().applyActionCode(oobCode);

    const user = flattenFirebaseUser(auth().currentUser);
    yield put(setUser({ ...user, emailVerified: true }));
  } catch (ex) {
    yield toast.error(ex.message);
  }
}

function* doLogin({ payload: { email, password } }) {
  try {
    yield auth().signInWithEmailAndPassword(email, password);
    let user = flattenFirebaseUser(auth().currentUser);

    const profileDoc = yield firestore()
      .doc(`users/${user.uid}`)
      .get();
    if (profileDoc.exists) {
      user = flattenFirebaseUser({
        ...auth().currentUser,
        ...profileDoc.data()
      });
    }

    yield put(setUser(user));
  } catch (ex) {
    yield toast.error(ex.message);
  }
}

function* doRequestPasswordReset({ payload: email }) {
  try {
    yield auth().sendPasswordResetEmail(email);
    yield put(passwordResetRequestSent());
    toast.success("Please check your email for a link to reset your password.");
  } catch (ex) {
    yield toast.error(ex.message);
  }
}

function* doChangePassword({ payload: { password, oobCode } }) {
  try {
    yield auth().confirmPasswordReset(oobCode, password);
    yield put(passwordResetSuccessful());
    toast.success(
      "Your password has been reset successfully. Please login with your new password."
    );
  } catch (ex) {
    yield toast.error(ex.message);
  }
}

function* doEditProfile({ payload: updatedUser }) {
  try {
    const {
      auth: { user }
    } = yield select();

    yield firestore()
      .doc(`users/${user.uid}`)
      .set(updatedUser, { merge: true });

    yield put(setUser({ ...user, ...updatedUser }));

    toast.success("Your profile has been updated successfully.");
  } catch (ex) {
    console.error(ex);
    yield toast.error(ex.message);
  }
}

function* authSaga() {
  yield takeLatest("auth/createAccount", createAccountSaga);
  yield takeLatest("auth/sendEmailVerification", sendEmailVerificationSaga);
  yield takeLatest("auth/doEmailVerification", doEmailVerificationSaga);
  yield takeLatest("auth/doLogin", doLogin);
  yield takeLatest("auth/doRequestPasswordReset", doRequestPasswordReset);
  yield takeLatest("auth/doChangePassword", doChangePassword);
  yield takeLatest("auth/doEditProfile", doEditProfile);
}

export default authSaga;
