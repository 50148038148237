import React from 'react';
import Icon from './Icon';

const Document = (props) => (
  <Icon
    {...props}
    path="M298 640h86v86h-86v-86zM298 470h86v84h-86v-84zM298 298h86v86h-86v-86zM470 640h256v86h-256v-86zM470 470h256v84h-256v-84zM470 298h256v86h-256v-86zM858 128q14 0 26 11t12 27v692q0 14-12 26t-26 12h-692q-16 0-27-12t-11-26v-692q0-38 38-38h692zM810 214h-596v596h596v-596z"
  />
);

export default Document;
