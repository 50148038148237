import React from 'react';
import { theme } from '../../theme';

const IconBase = ({ size = 24, path, title = '', color = 'black' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 1024 1024"
  >
    {title ? <title>{title}</title> : null}
    <path d={path} fill={theme.colors[color] || theme.colors.black} />
  </svg>
);

export default IconBase;
