import styled from "styled-components";
import { color } from "../../theme";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ full }) =>
    full
      ? `
    max-width: 920px;
    margin: 0 auto;
  `
      : ``}
`;

export const Breadcrumb = styled.div`
  background-color: ${({ theme: t }) => color(t, "cameoPink")};
  color: white;
  padding: 1rem 2.5rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  margin: 0;
`;

export const Content = styled.div`
  flex: 1;
  height: 100%;
  background-color: ${({ theme: t }) => color(t, "whiteSmoke")};
  padding: 2.5rem;
`;

export const ContentBlock = styled.div`
  background-color: white;
  padding: 1.5rem 2.5rem;
  margin-bottom: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;
