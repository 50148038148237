import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Switch, Route } from "react-router-dom";
import GlobalStyle from "./GlobalStyle";
import Header from "./components/Header";
import Login from "./components/Login";
import CreateAccount from "./components/CreateAccount";
import VerifyEmail from "./components/VerifyEmail";
import Auth from "./components/Auth";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import ExamFeedback from "./components/ExamFeedback";
import Admin from "./components/Admin";
import ModulePage from "./components/ModulePage";
import Chapter from './components/Chapter';
import { auth, firestore } from "./firebase";
import { setUser as _setUser } from "./redux/auth/auth.slice";
import { checkIsAdmin as _checkIsAdmin } from "./redux/admin/admin.slice";
import { flattenFirebaseUser } from "./helpers/auth";

import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./components/Dashboard";
import MoreInfo from "./components/MoreInfo";
import Exam from "./components/Exam";
import Enrol from "./components/Enrol";
import Payment from "./components/Payment";
import ScrollToTop from "./components/utility/ScrollToTop";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

class App extends Component {
  componentDidMount() {
    const { setUser, user, checkIsAdmin } = this.props;

    auth().onAuthStateChanged(async loggedInUser => {
      if (loggedInUser && (!user || user.email !== loggedInUser.email)) {
        auth()
          .currentUser.getIdToken(true)
          .then(idToken => {
            localStorage.setItem("idToken", idToken);
          })
          .catch(function(error) {
            console.error(error);
          });

        const profileDoc = await firestore()
          .doc(`users/${loggedInUser.uid}`)
          .get();
        if (profileDoc.exists) {
          loggedInUser = flattenFirebaseUser({
            ...loggedInUser,
            ...profileDoc.data()
          });
        }

        setUser(flattenFirebaseUser(loggedInUser));
        checkIsAdmin();
      } else {
        setUser(null);
      }
    });
  }

  render() {
    const { user, authLoading } = this.props;

    const checkAuth = (component, props) => {
      if (user) {
        const RouteComponent = component;

        if (!user.emailVerified) {
          return <VerifyEmail {...props} />;
        }

        if (!user.firstName || !user.lastName || !user.dob) {
          return <MoreInfo {...props} />;
        }

        return <RouteComponent {...props} />;
      }

      return <Login {...props} />;
    };

    return (
      <div className="App">
        <ScrollToTop>
          <GlobalStyle />
          <Page>
            <Header />
            {authLoading ? null : (
              <Switch>
                <Route
                  exact
                  path="/"
                  render={props => checkAuth(Dashboard, props)}
                />
                <Route
                  exact
                  path="/module/:moduleSlug"
                  render={props => checkAuth(ModulePage, props)}
                />
                <Route
                  exact
                  path="/module/:moduleSlug/chapter/:chapterSlug"
                  render={props => checkAuth(Chapter, props)}
                />
                <Route
                  exact
                  path="/chapter/:chapterSlug"
                  render={props => checkAuth(Chapter, props)}
                />
                <Route
                  exact
                  path="/exams/:examSlug"
                  render={props => checkAuth(Exam, props)}
                />
                <Route
                  exact
                  path="/enrol"
                  render={props => checkAuth(Enrol, props)}
                />
                <Route
                  exact
                  path="/payments"
                  render={props => checkAuth(Payment, props)}
                />
                <Route
                  exact
                  path="/exam-feedback/:attemptId"
                  render={props => <ExamFeedback {...props} />}
                />
                <Route path="/admin" component={Admin} />
                <Route exact path="/auth/action" component={Auth} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/create-account" component={CreateAccount} />
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                />
                <Route
                  exact
                  path="/reset-password"
                  component={ChangePassword}
                />
              </Switch>
            )}
          </Page>
          <ToastContainer />
        </ScrollToTop>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  authLoading: auth.loading,
});

const mapDispatchToProps = {
  setUser: _setUser,
  checkIsAdmin: _checkIsAdmin,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
