import React from "react";
import { connect } from "react-redux";
import { Wrap, Inner, Form as Content } from "./styled/Login.styled";
import { Title } from "./styled/Title.styled";
import { sendEmailVerification as _sendEmailVerification } from "../redux/auth/auth.slice";

const VerifyEmail = ({ user, sendEmailVerification }) => (
  <Wrap>
    <Inner>
      <Title>Verify Email</Title>
      <Content as="div">
        <p>
          An email has been sent to <strong>{user.email}</strong>. Please click
          the link in the email to verify your email address and activate your
          account.
        </p>
        <p>
          Not received an email? Click{" "}
          <a
            href="#"
            onClick={event => {
              event.preventDefault();
              sendEmailVerification();
            }}
          >
            here
          </a>{" "}
          to send again.
        </p>
      </Content>
    </Inner>
  </Wrap>
);

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

const mapDispatchToProps = {
  sendEmailVerification: _sendEmailVerification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmail);
