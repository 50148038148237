import styled from "styled-components";
import { darken } from "polished";

export const Button = styled.button`
  border: 0;
  font-family: inherit;
  background-color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.yellow};
  color: white;
  padding: 1rem 2rem;
  font-size: inherit;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  ${({ disabled }) =>
    disabled
      ? `
    opacity: 0.5;
    cursor: default;
  `
      : null}

  ${({ stretch }) =>
    stretch
      ? `
    width: 100%;
    display: block;
  `
      : null}

  &:disabled {
    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme, color }) =>
      darken(0.1, color ? theme.colors[color] : theme.colors.yellow)};
  }
`;

export const IconButton = styled.button`
  ${({ theme, color }) => `
    border: 0;
    font-family: inherit;
    background-color: transparent;
    color: ${color ? theme.colors[color] : 'inherit'};
    font-size: inherit;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    text-align: center;
    outline: none;
    padding: 0;

    &:disabled {
      opacity: 0.5;
    }

    path {
      fill: ${color ? theme.colors[color] : 'inherit'};
    }
  `}
`;
