import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Wrap,
  Inner,
  Form,
  FormGroup,
  Label,
  Input,
  Error
} from "./styled/Login.styled";
import { Title } from "./styled/Title.styled";
import { Button } from "./styled/Button.styled";
import { doRequestPasswordReset as _doRequestPasswordReset } from "../redux/auth/auth.slice";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("Required")
});

class ForgotPassword extends Component {
  handleRequestPasswordReset = values => {
    const { doRequestPasswordReset } = this.props;

    doRequestPasswordReset(values.email);
  };

  render() {
    const { user, submitted } = this.props;

    if (user || submitted) {
      return <Redirect to="/" />;
    }

    return (
      <Wrap>
        <Inner>
          <Title>Forgot Password</Title>
          <Formik
            onSubmit={this.handleRequestPasswordReset}
            validationSchema={LoginSchema}
            initialValues={{ email: "" }}
          >
            {({ errors, touched }) => (
              <Form>
                <p>
                  Please enter your email below to receive a link to reset your
                  password.
                </p>
                <FormGroup>
                  <Label>Email</Label>
                  <Input name="email" type="email" autoFocus />
                  {errors.email && touched.email ? (
                    <Error>{errors.email}</Error>
                  ) : null}
                </FormGroup>
                <Button type="submit" stretch color="middleBlue">
                  Reset Password
                </Button>
              </Form>
            )}
          </Formik>
        </Inner>
      </Wrap>
    );
  }
}

const mapStateToProps = ({ auth: { user, forms } }) => ({
  user,
  submitted: forms.forgotPassword.submitted
});

const mapDispatchToProps = {
  doRequestPasswordReset: _doRequestPasswordReset
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
