import React from 'react';
import arrayMove from 'array-move';
import { toast } from 'react-toastify'
import firestore from '../../firebase';
import { Wrap, Inner, Box, List, ListItem, ChapterBox, Controls, ChapterName, ExpandIcon, ExpandPanel, PaddedContainer } from "../styled/AdminChapters.styled";
import { Title } from "../styled/Title.styled";
import Select from "../styled/Select.styled";
import { IconButton, Button } from "../styled/Button.styled";
import { Card } from "../styled/Dashboard.styled";
import Delete from "../icons/Delete";
import ChevronUp from "../icons/ChevronUp";
import ChevronDown from "../icons/ChevronDown";
import { Label, Input } from '../styled/Form.styled';
import Edit from '../icons/Edit';

const NEW_MODULE_ID = 'new';

class ManageCourses extends React.Component {
  constructor(props) {
    super(props);

    const search = new URLSearchParams(this.props.location.search);

    this.state = {
      loading: false,
      courses: [],
      modules: [],
      filters: [],
      selectedCourse: (search.has('id') && search.get('id') !== NEW_MODULE_ID) ? search.get('id') : '',
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      await this.fetchCourses();
      await this.fetchModules();
      await this.fetchFilters();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  get hasUnsavedNewCourse() {
    return this.state.courses.some(course => course.id === NEW_MODULE_ID);
  }

  async fetchCourses() {
    try {
      const { docs } = await firestore().collection('courses').get();

      const courses = docs.map(d => ({
        id: d.id,
        ...d.data()
      }));

      this.setState({
        courses,
      }, () => {
          if ((!this.state.selectedCourse) && courses.length === 1) {
            this.handleExpand(courses[0].id)
          }
      });
    } catch (error) {
      console.error(error);
      await toast.error('Error fetching courses - please check the developer console');
    }
  }

  async fetchModules() {
    try {
      const { docs } = await firestore().collection('modules').get();

      const modules = docs.map(d => ({
        id: d.id,
        ...d.data()
      }));

      this.setState(((state) => ({
        modules,
        courses: state.courses.map((course) => ({
          ...course,
          modules: course.modules.map((id) => modules.find((mod) => mod.id === id)),
        }))
      })));
    } catch (error) {
      console.error(error);
      await toast.error('Error fetching modules - please check the developer console');
    }
  }

  async fetchFilters() {
    try {
      const { docs } = await firestore().collection('filters').get();

      const filters = docs.map(d => ({
        id: d.id,
        ...d.data()
      }));

      this.setState((state) => ({
        filters
      }));
    } catch (error) {
      console.error(error);
      await toast.error('Error fetching filters - please check the developer console');
    }
  }

  handleExpand(id) {
    const { history, location } = this.props;
    const { selectedCourse } = this.state;

    history.replace({
      pathname: location.pathname,
      search: selectedCourse === id ? '' : `id=${id}`,
    });

    this.setState((state) => ({
      selectedCourse: state.selectedCourse === id ? '' : id,
    }));
  }

  handleModuleSelect = (event) => {
    const id = event.target.value;
    if (id) {
      this.setState((state) => ({
        courses: state.courses.map((course) => (
          course.id === state.selectedCourse
            ? { ...course, modules: [...course.modules, state.modules.find(mod => mod.id === id)] }
            : course
        ))
      }))
    }
  }

  handleModuleDelete = (id) => {
    this.setState((state) => ({
      courses: state.courses.map((course) => (
        course.id === state.selectedCourse
          ? { ...course, modules: course.modules.filter(mod => mod.id !== id) }
          : course
      ))
    }))
  }

  handleModuleUp = (i) => {
    this.setState((state) => ({
      courses: state.courses.map((course) => (
        course.id === state.selectedCourse
          ? {
            ...course,
            modules: arrayMove(course.modules, i, i - 1),
          }
          : course
      ))
    }))
  }

  handleModuleDown = (i) => {
    this.setState((state) => ({
      courses: state.courses.map((course) => (
        course.id === state.selectedCourse
          ? {
            ...course,
            modules: arrayMove(course.modules, i, i + 1),
          }
          : course
      ))
    }))
  }

  handleInputChange = (event) => {
    let { value, name, min, max, type } = event.target;

    switch (type){
      case "number":
        if (value !== ""){
          value = Number(value);
          if (min && max){
            if (value < min) value = min;
            if(value > max) value = max;
          }
        }else{
          value = null;
        }
        break;
      default:
        break;
    }

    this.setState((state) => ({
      courses: state.courses.map((course) => (course.id === state.selectedCourse
        ? { ...course, [name]: value }
        : course
      ))
    }))
  }

  handleSubmit = async (courseId) => {
    try {
      const { id, ...course } = this.state.courses.find(c => c.id === courseId);

      const payload = {
        ...course,
        modules: course.modules.map(m => m.id),
      };

      if (courseId === NEW_MODULE_ID) {
        const doc = await firestore().collection('courses').add(payload);

        await toast.success('New course added!');

        this.setState((state) => ({
          courses: state.courses.map(course => course.id === NEW_MODULE_ID
            ? { ...course, id: doc.id }
            : course
          )
        }), () => {
          this.handleExpand(doc.id);
        });
      } else {
        await firestore().collection('courses').doc(courseId).update(payload);
        await toast.success('Course updated!');
      }
    } catch (error) {
      console.error(error);
      await toast.error('Error saving course - please check the developer console');
    }
  }

  handleDelete = async (id) => {
    try {
      if (id !== NEW_MODULE_ID) {
        await firestore().collection('courses').doc(id).delete();
        await toast.success('Course deleted!');
      }

      this.setState((state) => ({
        courses: state.courses.filter(course => course.id !== id),
      }), () => {
        this.handleExpand(id);
      });

    } catch (error) {
      console.error(error);
      await toast.error('Error deleting course - please check the developer console');
    }
  }

  handleAdd = () => {
    this.setState((state) => ({
      courses: [...state.courses, {
        id: NEW_MODULE_ID,
        name: 'NEW COURSE!',
        modules: [],
      }]
    }), () => {
      this.handleExpand(NEW_MODULE_ID);
    });
  }

  handleModuleEditClick = (id) => {
    this.props.history.push({
      pathname: '/admin/modules',
      search: `id=${id}`,
    });
  }

  handleMultiSelectAdd = (event) => {
    const {name, value} = event.target;

    this.setState((state) => ({
      courses: state.courses.map((course) => (course.id === state.selectedCourse
        ? { ...course, [name]: [...course[name] || [], value] }
        : course
      ))
    }));
  }

  handleMultiSelectRemove = (id, value) => {
    this.setState((state) => ({
      courses: state.courses.map((course) => (
        course.id === state.selectedCourse
          ? { ...course, [id]: course[id].filter(option => option !== value) }
          : course
      ))
    }))
  }

  render() {
    const {
      loading,
      courses,
      modules,
      filters,
      selectedCourse,
    } = this.state;

    if (loading) {
      return <Card to="#" onClick={(e) => e.preventDefault()} loading>Loading...</Card>;
    }

    return (
      <Wrap>
        <Inner>
          <Title>Courses</Title>
          <Box>
            <List>
              {courses.map((course) => (
                <ChapterBox key={course.id}>
                  <ChapterName>
                    {course.name}
                    {' - '}
                    {course.modules.reduce((count, mod) => count + mod.duration, 0)} weeks
                    {' '}
                    {`(inc. ${course.modules.reduce((count, mod) => count + (mod.remote ? mod.duration : 0), 0)} remote)`}
                    {course.id === NEW_MODULE_ID ? ' - UNSAVED' : ''}
                  <ExpandIcon
                    selected={selectedCourse === course.id}
                    onClick={() => this.handleExpand(course.id)}
                  />
                  </ChapterName>
                  <ExpandPanel open={selectedCourse === course.id}>
                    <PaddedContainer>
                      <PaddedContainer>
                        <Label>Name:</Label>
                        <Input
                          type="text"
                          name="name"
                          value={course.name}
                          onChange={this.handleInputChange}
                        />
                      </PaddedContainer>
                      <ChapterName>Modules:</ChapterName>
                      <List>
                        {course.modules.map((mod, i, mods) => (
                          <ListItem key={mod.id}>
                            {mod.name} - {mod.duration} weeks {mod.remote ? ' (remote)' : ''}
                            <Controls>
                              {i === 0 ? null : (
                                <IconButton
                                  title="Move Up"
                                  onClick={() => this.handleModuleUp(i)}
                                >
                                  <ChevronUp />
                                </IconButton>
                              )}
                              {i === mods.length - 1 ? null : (
                                <IconButton
                                  title="Move Down"
                                  onClick={() => this.handleModuleDown(i)}
                                >
                                  <ChevronDown />
                                </IconButton>
                              )}
                              <IconButton
                                title="Edit"
                                onClick={() => this.handleModuleEditClick(mod.id)}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                title="Remove"
                                onClick={() => this.handleModuleDelete(mod.id)}
                              >
                                <Delete />
                              </IconButton>
                            </Controls>
                          </ListItem>
                        ))}
                      </List>
                      <PaddedContainer>
                        <Select
                          label="Add Module:"
                          onChange={this.handleModuleSelect}
                        >
                          <option key="default" value="">Select Module</option>
                          {modules
                            .filter((mod) => !course.modules.some((cm) => cm.id === mod.id))
                            .map((mod) => (
                              <option key={mod.id} value={mod.id}>
                                {mod.name} - {mod.duration} weeks {mod.remote ? ' (remote)' : ''}
                              </option>
                            ))
                          }
                        </Select>
                      </PaddedContainer>
                        
                        {
                         filters.map(filter => {

                          let inputOptions = {...filter.options};

                          delete inputOptions.step;

                          let Comp;
                          let children = null;
                          let PreComp = null;

                          switch (filter.type){
                            case "multiselect":
                              Comp = Select;
                              inputOptions.label = `Add ${filter.name}:`;

                              children = [<option key="default" value="">Add a {filter.name}</option>];
                              children.push(filter.options.map(option => {
                                
                                if (course[filter.id] && course[filter.id].includes(option)) return null;

                                return (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                );
                              }
                              ));
                              
                              const opts = course[filter.id] || [];
                              PreComp = (<List>
                                {opts.length > 0 ? opts.map((filterOption) => (
                                  <ListItem key={filterOption}>
                                    {filterOption}
                                    <Controls>
                                      <IconButton
                                        title="Remove"
                                        onClick={() => this.handleMultiSelectRemove(filter.id, filterOption)}
                                      >
                                        <Delete />
                                      </IconButton>
                                    </Controls>
                                  </ListItem>
                                )) : <ListItem key="no-filter">No {filter.name} selected</ListItem>}
                              </List>);

                              inputOptions.onChange = this.handleMultiSelectAdd;

                              break;
                            case "select":
                              Comp = Select;
                              children = [<option key="default" value="">Select {filter.name}</option>];

                              children.push(filter.options.map(option => 
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ));
                              inputOptions.onChange = this.handleInputChange;

                              break;
                            default:
                              Comp = Input;
                              inputOptions.placeholder = `Enter ${filter.name}`;
                              inputOptions.onChange = this.handleInputChange;
                              break;
                          }
                          

                          return (
                            <div key={filter.name}>
                              <ChapterName>{filter.name}</ChapterName>
                              {PreComp}
                              <PaddedContainer key={filter.id}>
                                <Comp name={filter.id} value={course[filter.id]} type={filter.type} {...inputOptions}>{children}</Comp>
                              </PaddedContainer>
                            </div>
                          )
                         }) 
                        }
                      
                    </PaddedContainer>
                    <PaddedContainer>
                      <Button
                        color="cardinal"
                        onClick={() => this.handleSubmit(course.id)}
                      >
                        Save
                      </Button>
                      <Button
                        color="orangeSoda"
                        onClick={() => this.handleDelete(course.id)}
                      >
                        Delete
                      </Button>
                    </PaddedContainer>
                  </ExpandPanel>
                </ChapterBox>
              ))}
            </List>
            <PaddedContainer>
              <Button
                color="cardinal"
                onClick={this.handleAdd}
                disabled={this.hasUnsavedNewCourse}
              >
                Add Course
              </Button>
            </PaddedContainer>
          </Box>
        </Inner>
      </Wrap>
    );
  }
}

export default ManageCourses;
