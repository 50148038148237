import styled from "styled-components";

export const Wrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.a}px) {
    padding: 5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const Inner = styled.div`
  min-width: 320px;
  max-width: 900px;
  width: 100%;
`;

export const Paper = styled.div`
  padding: 2rem 2.5rem;
  background-color: white;
`;

export const Spacer = styled.div`
  margin-top: 2.5rem;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const QuestionWrap = styled.div`
  margin: 60px 0;
`;

export const Pass = styled.div`
  text-align: center;
  margin-bottom: 30px;
  text-align: center;
  color: white;
  background-color: ${({ theme }) => theme.colors.seaGreen};
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
`;

export const Fail = styled.div`
  text-align: center;
  margin-bottom: 30px;
  text-align: center;
  color: white;
  background-color: ${({ theme }) => theme.colors.orangeSoda};
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
`;

export const TimeElapsed = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const Scorecard = styled.div`
  border: 2px solid #eee;
  padding: 10px;
  margin: 0;
  margin-bottom: 30px;
  list-style-type: none;

  li {
    margin: 5px 0;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
`;
