import React from "react";
import {
  Wrap,
  GuideOuter,
  IdeOuter
} from "../styled/step-views/GuideIde.styles";
import Guide from "./Guide";
import Ide from "../Ide";

const GuideIde = ({ page }) => (
  <Wrap>
    <GuideOuter>
      <Guide page={page} />
    </GuideOuter>
    <IdeOuter>
      <Ide />
    </IdeOuter>
  </Wrap>
);

export default GuideIde;
