import styled from 'styled-components';

export const Span = styled.span`
  color: ${({ status, theme }) => {
    if (status === 'success') return theme.colors.success;
    if (status === 'warning') return theme.colors.warning;
    if (status === 'error') return theme.colors.error;
    return theme.colors.black;
  }}
`;
