import React, { Component, Fragment } from "react";
import { toast } from "react-toastify";
import firestore from '../firebase';
import { Wrap, Inner, Card } from "./styled/Dashboard.styled";
import { Title } from "./styled/Title.styled";

// TODO: add week numbers to module chapters
// const colors = ['cardinal', 'manatee'];

class ModulePage extends Component {
  state = {
    loaded: false,
    mod: null,
    chapters: [],
  }

  async componentDidMount() {
    try {
      await this.fetchModule();
      await this.fetchChapters();
    } catch (error) {
      console.error(error);
      toast.error('Unable to fetch module content.');
    } finally {
      this.setState({ loaded: true });
    }
  }

  async fetchModule() {
    const { moduleSlug } = this.props.match.params;
    const { docs } = await firestore().collection('modules').where('slug', '==', moduleSlug).get();

    const [doc] = docs;

    const mod = {
      id: doc.id,
      ...doc.data()
    };

    this.setState({ mod });
  }

  async fetchChapters() {
    const { mod } = this.state;

    const chapters = await Promise.all(mod.chapters.map(async (c) => {
      const doc = await firestore().collection('chapters').doc(c).get();
      return {
        id: doc.id,
        ...doc.data(),
      };
    }));

    this.setState({ chapters });
  }

  render() {
    const { loaded, mod, chapters } = this.state;

    return (
      <Wrap>
        <Inner>
          {!loaded ? (
            <Card to="#" onClick={(e) => e.preventDefault()} loading>Loading...</Card>
          ) : (
            <Fragment>
              <Title>{mod.name}</Title>
              {chapters.map((chapter) => (
                <Card
                  key={chapter.id}
                  color="cardinal"
                  to={`/module/${mod.slug}/chapter/${chapter.slug}`}
                >
                  {chapter.name}
                </Card>
              ))}
            </Fragment>
          )}
        </Inner>
      </Wrap>
    );
  }
}

export default ModulePage;
