import React from 'react';
import Icon from './Icon';

const ChevronDown = (props) => (
  <Icon
    {...props}
    path="M708 366l60 60-256 256-256-256 60-60 196 196z"
  />
);

export default ChevronDown;
