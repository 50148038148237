import React from 'react';

const Terms = () => (
  <>
    <h1 class="c17" id="h.t1hq4ju0bflv"><span class="c20">Student Terms &amp; Conditions</span></h1>
    <p class="c2 c4"><span class="c5"></span></p>
    <p class="c2"><span class="c0">Date created: 17th February 2020</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Date last updated: 17th February 2020</span></p>
    <p class="c2 c4"><span class="c5"></span></p>
    <p class="c2"><span class="c15">Manchester Codes</span><span class="c0">&nbsp;is the operating name of the company
        MCRCODES LTD registered with Companies House (company number: 10643142).</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c8">The course you are enrolling onto is </span><span class="c15">Software Engineer
        FastTrack</span><span class="c0">, which may be referenced to as &lsquo;programme&rsquo; or
        &lsquo;course&rsquo;.</span></p>
    <p class="c2 c4"><span class="c5"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0 start" start="1">
      <li class="c2 c3"><span class="c5">Classroom sessions</span></li>
    </ol>
    <p class="c1"><span class="c5"></span></p>
    <p class="c2"><span class="c0">For courses commencing on a Monday, your classroom sessions will be on the
        days/times:</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Monday 18:30-21:30</span></p>
    <p class="c2"><span class="c0">Wednesday 18:30-21:30</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">For courses commencing on a Tuesday, your classroom sessions will be on the
        days/times:</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Tuesday 18:30-21:30</span></p>
    <p class="c2"><span class="c0">Thursday 18:30-21:30</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="2">
      <li class="c2 c3"><span class="c5">Drop-in sessions</span></li>
    </ol>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Drop-in sessions differ from classroom sessions in that they aren&rsquo;t tied to any
        specific point in the course curriculum and are meant as an opportunity for students to get work done outside of
        class time with support from a mentor. Attendance isn&rsquo;t mandatory for drop-in sessions, and students are
        welcome to join at any point during the sessions. Drop-in sessions always operate at the following
        days/times:</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Saturday 10:00-16:00</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Drop-in sessions may be open to current and previous students of Manchester Codes.
      </span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c8">On rare occasions, w</span><span class="c8">here circumstances change outside of
        Manchester Codes reasonable control, Manchester Codes reserves the right to make changes to or cancel</span><span
        class="c8">&nbsp;drop-in sessions without advance notice.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="3">
      <li class="c2 c3"><span class="c5">Location</span></li>
    </ol>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Classes and drop-in sessions are normally delivered at Planetree House, 21-31 Oldham
        Street, Manchester, M1 1JG. In any event where a classroom or drop-in session is set to go ahead, but Manchester
        Codes is unable to deliver it in the aforementioned location then best endeavours will be made to find an
        alternative venue within Manchester City Centre.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="4">
      <li class="c2 c3"><span class="c5">Attendance, performance and outcomes</span></li>
    </ol>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">By enrolling into the Software Engineer FastTrack programme with Manchester Codes, you
        are committing to attend 100% of classroom sessions - excluding &ldquo;drop-in&rdquo; sessions on Saturdays - with
        the exception that Manchester Codes is notified of absence in advance of the session and the reason for absence is
        deemed reasonable by Manchester Codes (such as illness, compassionate leave, carers leave, or other events beyond
        your control). Outside of classroom sessions, you are expected to spend an additional 15-20 hours a week studying
        on the material set by your teaching team. Manchester Codes reserves the right to ask you to leave the programme
        if attendance and/or performance is dissatisfactory.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c8">Manchester Codes makes no guarantees of course outcomes, including
        employment.</span><span class="c0">&nbsp;Any previous outcomes stated on the website or elsewhere by Manchester
        Codes are specific to previous students of Manchester Codes and not deemed to be representative of outcomes for
        future cohorts. </span></p>
    <p class="c2 c4"><span class="c5"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="5">
      <li class="c2 c3"><span class="c5">Paying the balance upfront</span></li>
    </ol>
    <p class="c1"><span class="c5"></span></p>
    <p class="c2"><span class="c0">If you choose to pay for the full balance upfront, then your place on the course is
        confirmed following payment of the full balance. In any circumstance, the full balance is due 2 weeks prior to the
        start of the course or - if this date has passed - immediately following enrolment. Failure to do so may result in
        your place being given to somebody else.</span></p>
    <p class="c2"><span class="c0">You can make payments towards the full balance on a separate Payments screen, which you
        will be redirected to upon enrolment. We accept all major debit and credit cards, or you can make payment into our
        account via BACS (please email us on team@manchestercodes.com if you do choose to pay via BACS, so that we can
        reconcile your payment(s)). Note that a minimum payment of &pound;750.00 applies.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">Any payments made via BACS should be made to:</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">MCRCODES LTD</span></p>
    <p class="c2"><span class="c0">Account Number: 32725116</span></p>
    <p class="c2"><span class="c0">Sort Code: 56-00-49</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c8">The full course balance is due prior to your course start date. </span><span
        class="c8">Manchester Codes reserves the right to pass any outstanding balance to a debt collection agency if
      </span><span class="c0">this date has passed.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="6">
      <li class="c2 c3"><span class="c5">Paying via finance</span></li>
    </ol>
    <p class="c1"><span class="c5"></span></p>
    <p class="c2"><span class="c8">You can choose to spread the cost of the course over 6-12 months by applying for
        finance through our finance partner </span><span class="c8 c21">Payitmonthly</span><span class="c0">. In this
        circumstance, you would be required to pay a non-refundable deposit of &pound;750.00 towards the full balance
        following enrolment. Should your finance application not be successful, this amount will be refunded to you.
        Following payment of this deposit, we will be in touch to arrange the setting up of the finance agreement. Note
        that this is a finance agreement, and as such you will be subject to a credit check and affordability
        criteria.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c8">You can make payment of your deposit on a separate Payments screen, which you will be
        redirected to upon enrolment. We accept all major debit and credit cards, or you can make payment into our account
        via BACS (please email us on </span><span class="c18 c8"><a class="c16"
          href="mailto:team@manchestercodes.com">team@manchestercodes.com</a></span><span class="c0">&nbsp;if you do
        choose to pay via BACS, so that we can reconcile your payment).</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c8">You are obligated to pay your full course balance, and Manchester Codes reserves the
        right to pass any outstanding balance to a debt collection agency and can take legal action if payments
        aren&rsquo;t made on time</span><span class="c0">&nbsp;or in full by the end of the course.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="7">
      <li class="c2 c3"><span class="c5">Non-refundable deposit</span></li>
    </ol>
    <p class="c1"><span class="c5"></span></p>
    <p class="c2"><span class="c8">On aforementioned payment methods, </span><span class="c0">&pound;750.00 of the balance
        paid towards your course fees is deemed to be a non-refundable deposit. In any event you don&rsquo;t commence on
        the course on the selected start date, you leave the course early regardless of circumstance, or Manchester Codes
        asks you to leave the course on reasonable grounds of attendance and/or &nbsp;and there is a decision at the
        discretion of Manchester Codes to partially refund, any amount to be refunded will exclude this non-refundable
        deposit.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">If you are applying for the course through our finance partner Payitmonthly, and
        Payitmontly and/or Manchester Codes choose not to grant finance, then you will be eligible for a refund of the
        &pound;750.00 deposit.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="8">
      <li class="c2 c3"><span class="c5">VAT</span></li>
    </ol>
    <p class="c1"><span class="c5"></span></p>
    <p class="c2"><span class="c0">Fees and charges include VAT at a rate of 20% at the time of order.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="9">
      <li class="c2 c3"><span class="c5">Early withdrawal from the course and refunds</span></li>
    </ol>
    <p class="c2 c4"><span class="c5"></span></p>
    <p class="c2"><span class="c0">We offer a cooling off period of 14 days.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c8">If during or prior the course start date you would like to suspend or withdraw from
        your studies we ask that you do so by contacting us via </span><span class="c18 c8"><a class="c16"
          href="mailto:team@manchestercodes.com">team@manchestercodes.com</a></span><span class="c0">. </span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c8">If you</span><span class="c0">&nbsp;withdraw from your studies or are asked to leave
        the course, you may be eligible for a partial refund based on how long you have been on the course. The &pound;750
        non-refundable deposit is not refundable under any circumstance other than where mentioned elsewhere in these
        terms and conditions.</span></p>
    <p class="c2 c4"><span class="c5"></span></p><a id="t.ffe878519856b7e3a1e99330712da2d39d728b94"></a><a id="t.0"></a>
    <table class="c19">
      <tbody>
        <tr class="c14">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c6"><span class="c5">Withdrawal from course </span></p>
            <p class="c6"><span class="c0">(from course start date)</span></p>
          </td>
          <td class="c9" colspan="1" rowspan="1">
            <p class="c6"><span class="c5">Refund Amount</span></p>
          </td>
        </tr>
        <tr class="c14">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c6"><span class="c0">Upto 14 days (End of Week 2)</span></p>
          </td>
          <td class="c9" colspan="1" rowspan="1">
            <p class="c6"><span class="c0">Total course fee (minus non refundable deposit)</span></p>
          </td>
        </tr>
        <tr class="c14">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c6"><span class="c8">Week 3 </span><span class="c0">to end of Week 6</span></p>
          </td>
          <td class="c9" colspan="1" rowspan="1">
            <p class="c6"><span class="c0">Full balance (minus non refundable deposit) * 75%</span></p>
          </td>
        </tr>
        <tr class="c14">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c6"><span class="c0">Week 7 to end of Week 12</span></p>
          </td>
          <td class="c9" colspan="1" rowspan="1">
            <p class="c6"><span class="c0">Full balance (minus non refundable deposit) * 50%</span></p>
          </td>
        </tr>
        <tr class="c14">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c6"><span class="c0">Week 13 to end of Week 18</span></p>
          </td>
          <td class="c9" colspan="1" rowspan="1">
            <p class="c6"><span class="c0">Full balance (minus non refundable deposit) * 25%</span></p>
          </td>
        </tr>
        <tr class="c14">
          <td class="c10" colspan="1" rowspan="1">
            <p class="c6"><span class="c0">Week 19 onwards</span></p>
          </td>
          <td class="c9" colspan="1" rowspan="1">
            <p class="c2"><span class="c0">&pound;0</span></p>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c2 c4"><span class="c5"></span></p>
    <p class="c2"><span class="c8">i)</span><span class="c15">&nbsp;</span><span class="c0">If you decide to suspend or
        terminate your student agreement and leave us 14 days or prior into the Software Engineer FastTrack course, on a
        discretionary basis we will endeavour to offer you a place on our future cohorts for you to resume your studies,
        giving you the opportunity to leave this cohort and join the next course for no additional fee and a new payment
        term will be agreed. Your non-refundable deposit will not be returned. </span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">ii) Any refund amount will be capped at the total balance paid to Manchester Codes by
        the student minus the non-refundable deposit. Should this amount be negative, Manchester Codes reserves the right
        to collect this amount from you (the learner).</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">iii) If an agreement is made between you (the learner) and Manchester Codes to defer
        your enrolment and transfer you to a later start date, you will not be subject to any difference in course fees,
        but you may be expected to adhere to any changes in made to the student terms and conditions prior to the updated
        course start date. </span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="10">
      <li class="c2 c3"><span class="c5">Code of Conduct </span></li>
    </ol>
    <p class="c1"><span class="c5"></span></p>
    <p class="c2"><span class="c0">When enrolling into one of our courses or being on our premises, we expect that you
        (the learner) read, understand and follow our code of conduct. </span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c8">Manchester Codes reserves the right to ask you to leave the programme with immediate
        effect, if </span><span class="c8">you (the learner)</span><span class="c8">&nbsp;fail to comply with our code of
        conduct</span><span class="c0">.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c8">For our code of conduct please </span><span class="c18 c8"><a class="c16"
          href="https://www.google.com/url?q=https://docs.google.com/document/d/1Jw2hDl2v0oANh0fhNKQtcrSN1VVuOFgRjxhQBqpNfTk/edit?usp%3Dsharing&amp;sa=D&amp;ust=1581983119794000">see
          here</a></span><span class="c0">.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="11">
      <li class="c2 c3"><span class="c5">Photography/Video/Recording</span></li>
    </ol>
    <p class="c1"><span class="c5"></span></p>
    <p class="c2"><span class="c0">We - or contractors acting on our behalf - may photograph, film and/or record you
        during teaching sessions and events for marketing purposes. Intellectual Property rights for this content belong
        to Manchester Codes. We reserve the right to modify the content in any way we see fit. No remuneration or
        royalties will be given for ongoing use of this content.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="12">
      <li class="c2 c3"><span class="c5">Right to study</span></li>
    </ol>
    <p class="c1"><span class="c5"></span></p>
    <p class="c2"><span class="c0">By enrolling on the course, you must have the right to live in the UK for the whole
        duration of the course. We may ask you for evidence of this prior to the course commencing.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="13">
      <li class="c2 c3"><span class="c5">Intellectual Property</span></li>
    </ol>
    <p class="c1"><span class="c5"></span></p>
    <p class="c2"><span class="c8">All lectures, teaching materials and other resources provided are intellectual property
        of Manchester Codes, and are not to be recorded and/or redistributed without permission in writing from the
        managing director of Manchester Codes. Any requests of such nature should be directed in an email to </span><span
        class="c18 c8"><a class="c16" href="mailto:joe@manchestercodes.com">joe@manchestercodes.com</a></span><span
        class="c0">.</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="14">
      <li class="c2 c3"><span class="c15">Delivery of Programme</span></li>
    </ol>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">i. Manchester Codes will provide you with tuition, learning materials and opportunities
        and other related services which will lead to the completion of the course, subject to your attendance and
        successfully fulfilling the requirements of your modules. Specific details relating to the delivery of your
        Programme will be provided before or at the time of your enrolment on your Programme. </span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c8">ii. Where circumstances change outside of Manchester Codes reasonable control,
        Manchester Codes reserves the right to make changes to or cancel certain elements of or the entirety of the
        Programme at any time without liability to you. Circumstances falling outside of the Manchester Codes control
        shall include but not be limited to acts of God, industrial action or disputes (including action or disputes
        involving the Manchester Codes employees), over or under demand from students, staff illness, lack of funding,
        extreme or severe weather, fire, malicious damage, flood, natural disaster, war, terrorism, civil disorder,
        political unrest, explosion, national emergencies, government restrictions, failure of a utility service,
        transport or telecommunication network, default of third party suppliers and subcontractors and concern with
        regard to the transmission of serious illness (event beyond Manchester Codes control).</span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="15">
      <li class="c2 c3"><span class="c5">Confidentiality agreement</span></li>
    </ol>
    <p class="c2 c4"><span class="c5"></span></p>
    <p class="c2"><span class="c0">i. You undertake to keep confidential and not disclose to any third party, or use
        yourself (other than for the purposes permitted under or in accordance with this Agreement), any: <br /></span></p>
    <ol class="c12 lst-kix_ouyo6n1rdqd-0 start" start="1">
      <li class="c2 c7"><span class="c0">confidential or secret information in any form directly or indirectly belonging
          or relating to the Manchester Codes, or its business or affairs and whether disclosed by the Manchester Codes
          organisation and received by you or otherwise gathered by you and whether or not such information has been
          developed, modified or improved; </span></li>
      <li class="c2 c7"><span class="c0">any issues which you have experienced or are experiencing at the organisation or
          in relation to your Programme; </span></li>
      <li class="c2 c7"><span class="c0">any disciplinary or investigative proceedings; </span></li>
      <li class="c2 c7"><span class="c0">the terms of this Agreement; </span></li>
      <li class="c2 c7"><span class="c8">anything else which Manchester Codes notifies to you as being confidential from
          time to time, </span><span class="c15">(Confidential Information)</span><span class="c0">. </span></li>
    </ol>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">ii. These confidentiality obligations shall survive the expiry or termination of this
        Agreement for any reason, but shall not apply to any Confidential Information which: </span></p>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_izxgvq1dhnzn-0 start" start="1">
      <li class="c2 c7"><span class="c0">is publicly known at the time of disclosure; or </span></li>
      <li class="c2 c7"><span class="c0">becomes publicly known otherwise than through a breach of this Agreement by you;
          or </span></li>
      <li class="c2 c7"><span class="c0">can be proved by you to have reached you otherwise than by being communicated by
          Manchester Codes including being known to you prior to disclosure, having been developed by or for you wholly
          independently of Manchester Codes, or having been obtained from a third party without any restriction on
          disclosure on such third party of which you are aware, having made due enquiry; or d. is required by law,
          regulation or order of a competent authority (including any regulatory or governmental body or securities
          exchange) to be disclosed by you, provided that, where practicable, Manchester Codes is given reasonable advance
          notice of the intended disclosure.</span></li>
    </ol>
    <p class="c2 c4"><span class="c0"></span></p>
    <p class="c2"><span class="c0">&nbsp;iii. You shall not use any of Manchester Codes Confidential Information for any
        purpose other than to perform your obligations under this Agreement</span></p>
    <p class="c2 c4"><span class="c5"></span></p>
    <ol class="c12 lst-kix_p9lw1pad8ttv-0" start="16">
      <li class="c2 c3"><span class="c5">Privacy</span></li>
    </ol>
    <p class="c2 c4"><span class="c5"></span></p>
    <ol class="c12 lst-kix_wgf34dpt9p7s-0 start" start="1">
      <li class="c2 c3"><span class="c0">Your privacy is critical to us. We respect your privacy and comply with the
          General Data Protection Regulation with regard to your personal information.</span></li>
      <li class="c2 c3"><span class="c8">These Terms and Conditions should be read alongside, and are in addition to our
          policies, including our privacy policy and cookies policy that can be found </span><span class="c18 c8"><a
            class="c16"
            href="https://www.google.com/url?q=https://www.manchestercodes.com/privacy-policy&amp;sa=D&amp;ust=1581983119801000">here</a></span><span
          class="c8">.</span></li>
      <li class="c2 c3"><span class="c0">For the purpose of there Terms and Conditions:</span></li>
    </ol>
    <ol class="c12 lst-kix_wgf34dpt9p7s-1 start" start="1">
      <li class="c2 c7"><span class="c0">&lsquo;Data protection Laws&rsquo; means any applicable law relating to the
          processing of Personal Data, including, but not limited to the Directive 95/46/EC (Data protection Directive) or
          the GDPR.</span></li>
      <li class="c2 c7"><span class="c0">&lsquo;GDPR&rsquo; means the General Data Protection Regulation (EU)
          2016/679.</span></li>
      <li class="c2 c7"><span class="c0">&lsquo;Data Controller&rsquo;, &lsquo;Personal Data&rsquo; and
          &lsquo;Processing&rsquo; shall have the same meaning as in GDPR.</span></li>
    </ol>
    <p class="c2 c4"><span class="c0"></span></p>
    <ol class="c12 lst-kix_wgf34dpt9p7s-0" start="4">
      <li class="c2 c3"><span class="c0">Where you supply Personal Data to us so we can provide Services and Goods to you,
          and we Process that Personal Data in the course of providing the Services and goods to you, we will comply with
          our obligations imposed by the Data Protection Laws:</span></li>
    </ol>
    <ol class="c12 lst-kix_wgf34dpt9p7s-1 start" start="1">
      <li class="c2 c7"><span class="c0">Before or at the time of collecting Personal Data, we will identify the purposes
          for which information is collected;</span></li>
      <li class="c2 c7"><span class="c0">We will only Process Personal data for the purposes identified;</span></li>
      <li class="c2 c7"><span class="c0">We will respect your rights in relation to you Personal Data; and</span></li>
      <li class="c2 c7"><span class="c0">We will implement technical and organisational measures to ensure your Personal
          Data is secure.</span></li>
    </ol>
    <ol class="c12 lst-kix_wgf34dpt9p7s-0" start="5">
      <li class="c2 c3"><span class="c8">For any enquiries or complaints regarding data privacy, you can email:
        </span><span class="c8 c18"><a class="c16"
            href="mailto:team@manchestercodes.com">team@manchestercodes.com</a></span><span class="c8">.</span>
      </li>
    </ol>
  </>
);

export default Terms;
