import React, { Component } from "react";
import { connect } from "react-redux";
import { Wrap, Inner, Paper, Buttons } from "./styled/Exam.styled";
import { Title } from "./styled/Title.styled";
import { Content, Question } from "./exam-components";
import exams from "../data/exams.json";
import {
  setExam as _setExam,
  startExam as _startExam,
  nextQuestion as _nextQuestion,
  prevQuestion as _prevQuestion,
  completeExam as _completeExam
} from "../redux/exam/exam.slice";
import { Button } from "./styled/Button.styled";
import moment from "moment";

class Exam extends Component {
  state = {
    exams: [],
    loading: true
  };

  componentDidMount() {
    const {
      match: {
        params: { examSlug }
      },
      setExam
    } = this.props;

    setExam(exams.find(exam => exam.slug === examSlug));
  }

  componentDidUpdate(prevProps) {
    const { currentQuestion, status } = this.props;

    if (
      prevProps.currentQuestion !== currentQuestion ||
      prevProps.status !== status
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      exam,
      currentQuestion,
      status,
      startExam,
      prevQuestion,
      nextQuestion,
      completeExam,
      disabled,
      previousExam,
      history
    } = this.props;

    if (!exam) {
      return null;
    }

    const { questions } = exam;

    const statusComponents = {
      summary: <Content content={exam.summary} />,
      started: <Question {...currentQuestion} />,
      conclusion: <Content content={exam.conclusion} />
    };

    let nextExamDate = null;
    if (previousExam && disabled) {
      nextExamDate = moment
        .unix(previousExam.startTime)
        .add(7, "days")
        .format("DD/MM/YYYY hh:mm");
    }

    return (
      <Wrap>
        <Inner>
          <Title>{exam.name}</Title>
          <Paper>
            {statusComponents[status]}
            {disabled && !(previousExam && previousExam.passed) ? (
              <strong>
                You can re-sit this exam on {nextExamDate}. In the meantime,
                please continue to go over the preparation material.
              </strong>
            ) : null}
          </Paper>
          {status === "summary" ? (
            <Buttons>
              {!previousExam || !previousExam.passed ? (
                <Button
                  disabled={disabled}
                  onClick={() => startExam()}
                  color="seaGreen"
                  stretch
                >
                  Start Exam
                </Button>
              ) : null}
              {previousExam && previousExam.feedback.length ? (
                <Button
                  onClick={() => history.push(`/exam-feedback/${previousExam.id}`)}
                  color="cardinal"
                  stretch
                >
                  View Feedback
                </Button>
              ) : null}
            </Buttons>
          ) : (
            ``
          )}
          {status === "started" ? (
            <Buttons>
              {currentQuestion === questions[0] ? (
                <div />
              ) : (
                <Button color="middleBlue" onClick={() => prevQuestion()}>
                  Previous Question
                </Button>
              )}
              {currentQuestion === questions[questions.length - 1] ? (
                <Button color="seaGreen" onClick={() => completeExam()}>
                  Complete Exam
                </Button>
              ) : (
                <Button color="blue" onClick={() => nextQuestion()}>
                  Next Question
                </Button>
              )}
            </Buttons>
          ) : (
            ``
          )}
        </Inner>
      </Wrap>
    );
  }
}

const mapStateToProps = ({
  exam: { exam, currentQuestion, status, disabled, previousExam }
}) => ({
  exam,
  currentQuestion,
  status,
  disabled,
  previousExam
});

const mapDispatchToProps = {
  setExam: _setExam,
  startExam: _startExam,
  nextQuestion: _nextQuestion,
  prevQuestion: _prevQuestion,
  completeExam: _completeExam
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Exam);
