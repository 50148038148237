import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Wrap,
  Inner,
  Paper,
  Buttons,
  QuestionWrap,
  Pass,
  Fail,
  TimeElapsed,
  Scorecard,
  TextArea
} from "./styled/ExamFeedback.styled";
import { Title } from "./styled/Title.styled";
import { Content } from "./exam-components";
import {
  getPreviousExam as _getPreviousExam,
  updateFeedback as _updateFeedback,
  leaveFeedback as _leaveFeedback
} from "../redux/exam/exam.slice";
import { Button } from "./styled/Button.styled";
import moment from "moment";

class ExamFeedback extends Component {
  componentDidMount() {
    const {
      match: {
        params: { attemptId }
      },
      getPreviousExam
    } = this.props;

    getPreviousExam(attemptId);
  }

  render() {
    const {
      exam,
      examAttempt,
      feedback,
      updateFeedback,
      admin,
      leaveFeedback,
      examUser
    } = this.props;

    if (!exam || !examAttempt || !examUser) {
      return null;
    }

    const duration = moment
      .unix(examAttempt.finishTime)
      .diff(moment.unix(examAttempt.startTime), "minutes");
    const editable = admin && !examAttempt.mark;

    return (
      <Wrap>
        <Inner>
          <Title>{exam.name}</Title>
          <Paper>
            <h1>
              {examUser.firstName} {examUser.lastName}
            </h1>
            {typeof examAttempt.mark === "number" ? (
              <Fragment>
                {examAttempt.passed ? (
                  <Pass>PASS - {examAttempt.mark}%</Pass>
                ) : (
                  <Fail>
                    {editable ? `Awaiting mark` : `FAIL - ${examAttempt.mark}%`}
                  </Fail>
                )}
              </Fragment>
            ) : null}
            <TimeElapsed>Time taken: {duration} minutes</TimeElapsed>
            <Scorecard>
              <li>
                <strong>Marking scale</strong>
              </li>
              <li>
                <strong>0 - Bad</strong> - No solution given or solution isn't
                what is being asked for
              </li>
              <li>
                <strong>1 - Acceptable</strong> - Solution may contain many
                mistakes or be incomplete, but demonstrates correct mindset
                and/or approach
              </li>
              <li>
                <strong>2 - Good</strong> - Solution is correct but may contain
                minor mistakes
              </li>
              <li>
                <strong>3 - Excellent</strong> - Solution is 100% correct
              </li>
            </Scorecard>
            {exam.questions.map((question, index) => {
              const answer = examAttempt.answers.find(
                answer => answer.questionId === question.id
              );
              const questionFeedback =
                feedback.find(
                  singleFeedback => singleFeedback.questionId === question.id
                ) || {};

              return (
                <QuestionWrap>
                  <h2>Question {index + 1}</h2>
                  <Content content={question.content}></Content>
                  <h3>Given answer</h3>
                  {answer ? (
                    <Content
                      content={"```\n" + answer.answer + "\n```"}
                    ></Content>
                  ) : (
                    "Not attempted"
                  )}
                  <h3>Score</h3>
                  {editable ? (
                    <select
                      value={questionFeedback.score}
                      onChange={e =>
                        updateFeedback({
                          questionId: question.id,
                          field: "score",
                          value: Number(e.target.value)
                        })
                      }
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  ) : (
                    questionFeedback.score
                  )}
                  <h3>Feedback</h3>
                  {editable ? (
                    <TextArea
                      value={questionFeedback.comment}
                      onChange={e =>
                        updateFeedback({
                          questionId: question.id,
                          field: "comment",
                          value: e.target.value
                        })
                      }
                    ></TextArea>
                  ) : (
                    <Content content={questionFeedback.comment} />
                  )}
                </QuestionWrap>
              );
            })}
          </Paper>
          {editable ? (
            <Buttons>
              <Button color="seaGreen" onClick={() => leaveFeedback()}>
                Save Feedback
              </Button>
            </Buttons>
          ) : null}
        </Inner>
      </Wrap>
    );
  }
}

const mapStateToProps = ({ exam: { exam, previousExam, feedback, user }, admin }) => ({
  exam,
  examAttempt: previousExam,
  feedback,
  examUser: user,
  admin: admin.authorised
});

const mapDispatchToProps = {
  getPreviousExam: _getPreviousExam,
  updateFeedback: _updateFeedback,
  leaveFeedback: _leaveFeedback
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamFeedback);
