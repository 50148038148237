import { takeLatest, put, select } from "redux-saga/effects";
import {
  logAnswer,
  setAttemptId,
  updateStatus,
  setDisabled,
  setPreviousExam,
  setExamNoRequest,
  setExamUser,
  goForward
} from "./exam.slice";
import { updateOutput, updateCode } from "../ide/ide.slice";
import { firestore } from "../../firebase";
import moment from "moment";
import { toast } from "react-toastify";
import exams from "../../data/exams";

function* prevQuestion() {
  const {
    exam: {
      exam: { questions },
      currentQuestion: prevQuestion,
      answers
    },
    ide: { code }
  } = yield select();

  const prevQuestionIndex = questions.indexOf(prevQuestion);
  const prevQuestionAnswer = (answers[prevQuestionIndex] || {}).answer;

  const currentQuestionIndex = prevQuestionIndex + 1;
  const currentQuestionId = questions[currentQuestionIndex].id;

  yield put(logAnswer({ questionId: currentQuestionId, answer: code }));

  if (prevQuestionAnswer) {
    yield put(updateCode(prevQuestionAnswer));
  }

  yield put(updateOutput([]));
}

function* nextQuestion() {
  const {
    exam: {
      exam: { questions },
      currentQuestion,
      answers
    },
    ide: { code }
  } = yield select();

  const currentQuestionIndex = questions.indexOf(currentQuestion);

  const nextQuestionAnswer = (answers[currentQuestionIndex + 1] || {}).answer;
  if (nextQuestionAnswer) {
    yield put(updateCode(nextQuestionAnswer));
  }

  yield put(logAnswer({ questionId: currentQuestion.id, answer: code }));
  yield put(goForward());
  yield put(updateOutput([]));
}

function* startExam() {
  const {
    auth: {
      user: { uid }
    },
    exam: { exam }
  } = yield select();

  try {
    const examRef = yield firestore()
      .collection("exam-attempts")
      .add({
        userId: uid,
        examId: exam.id,
        startTime: firestore.Timestamp.now(),
        answers: [],
        feedback: [],
        finishTime: null,
        mark: null,
        passed: null,
      });

    yield put(setAttemptId(examRef.id));
  } catch (ex) {
    toast.error(
      "There was a problem starting the exam. Please try again later."
    );

    yield put(updateStatus("summary"));
  }
}

function* completeExam() {
  const {
    exam: { answers, attemptId, currentQuestion },
    ide: { code }
  } = yield select();

  const lastAnswer = { questionId: currentQuestion.id, answer: code };

  yield firestore()
    .doc(`exam-attempts/${attemptId}`)
    .update({
      answers: [...answers, lastAnswer],
      finishTime: firestore.Timestamp.now()
    });

  yield put(updateStatus("conclusion"));
}

function* leaveFeedback() {
  const {
    exam: { previousExam, feedback }
  } = yield select();

  const totalMarks = feedback.reduce(
    (total, singleFeedback) => total + singleFeedback.score,
    0
  );
  const possibleMarks = feedback.length * 3;
  const percentage = Math.ceil((totalMarks / possibleMarks) * 100);

  const params = {
    feedback,
    passed: percentage >= 70,
    mark: percentage
  };

  yield firestore()
    .doc(`exam-attempts/${previousExam.id}`)
    .update(params);

  if (params.passed) {
    yield firestore()
      .doc(`users/${previousExam.userId}`)
      .set({ eligible: true }, { merge: true });
  }

  yield put(setPreviousExam({ ...previousExam, ...params }));
}

function* getPreviousExam({ payload: attemptId }) {
  const prevExamDoc = yield firestore()
    .doc(`exam-attempts/${attemptId}`)
    .get();

  const previousExam = {
    id: prevExamDoc.id,
    ...prevExamDoc.data()
  };

  previousExam.startTime = previousExam.startTime.seconds;
  previousExam.finishTime = previousExam.finishTime.seconds;

  const exam = exams.find(exam => exam.id === previousExam.examId);

  const userDoc = yield firestore()
    .doc(`users/${previousExam.userId}`)
    .get();

  const user = {
    uid: userDoc.uid,
    ...userDoc.data()
  };

  yield put(setExamUser(user));
  yield put(setExamNoRequest(exam));
  return yield put(setPreviousExam(previousExam));
}

function* setExam() {
  const {
    auth: { user },
    exam: { exam }
  } = yield select();

  const prevExamSnapshot = yield firestore()
    .collection("exam-attempts")
    .where("examId", "==", exam.id)
    .where("userId", "==", user.uid)
    .orderBy("startTime", "desc")
    .limit(1)
    .get();

  if (prevExamSnapshot.empty) {
    return yield put(setDisabled(false));
  }

  const examDocs = [];
  prevExamSnapshot.forEach(examDoc => {
    const singleExam = {
      id: examDoc.id,
      ...examDoc.data()
    };

    singleExam.startTime = singleExam.startTime.seconds;
    delete singleExam.finishTime;

    examDocs.push(singleExam);
  });

  const previousExam = examDocs[examDocs.length - 1];

  if (moment().diff(moment.unix(previousExam.startTime), "days") < 7) {
    return yield put(setPreviousExam(previousExam));
  }

  return yield put(setDisabled(false));
}

function* examSaga() {
  yield takeLatest("exam/prevQuestion", prevQuestion);
  yield takeLatest("exam/nextQuestion", nextQuestion);
  yield takeLatest("exam/completeExam", completeExam);
  yield takeLatest("exam/startExam", startExam);
  yield takeLatest("exam/setExam", setExam);
  yield takeLatest("exam/getPreviousExam", getPreviousExam);
  yield takeLatest("exam/leaveFeedback", leaveFeedback);
}

export default examSaga;
