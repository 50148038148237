import { takeLatest, put } from "redux-saga/effects";
import { auth } from "../../firebase";
import { setAuthorised } from "./admin.slice";

function* checkIsAdmin() {
  const idTokenResult = yield auth().currentUser.getIdTokenResult();

  yield put(setAuthorised(!!idTokenResult.claims.admin || !!idTokenResult.claims.tutor));
}

function* adminSaga() {
  yield takeLatest("admin/checkIsAdmin", checkIsAdmin);
}

export default adminSaga;
