import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.a}px) {
    padding: 5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .mde-text {
    font-size: inherit;
    line-height: 1.5;
  }
`;

export const Inner = styled.div`
  min-width: 320px;
  max-width: 1030px;
  width: 100%;
`;

export const Box = styled.div`
  background-color: white;
  padding: 1.25rem 0;
`;

export const List = styled.nav`
  margin: 0;
  padding: 0;
`;

export const ListItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 1.25rem 2.5rem;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.5s ease-in-out;

  span {
    display: flex;
    width: 3rem;
    height: 3rem;
    border: 1px solid #ddd;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #ccc;
    margin-right: 2rem;
    background-color: white;
  }

  &:hover {
    background-color: #eee;
  }
`;
