import React from 'react';
import Icon from './Icon';

const CheckCircle = (props) => (
  <Icon
    {...props}
    path="M426 726l384-384-60-62-324 324-152-152-60 60zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"
  />
);

export default CheckCircle;
