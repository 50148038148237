import React from 'react';
import arrayMove from 'array-move';
import { toast } from 'react-toastify'
import firestore from '../../firebase';
import { Wrap, Inner, Box, List, ListItem, ChapterBox, Controls, ChapterName, ExpandIcon, ExpandPanel, PaddedContainer } from "../styled/AdminChapters.styled";
import { Title } from "../styled/Title.styled";
import Select from "../styled/Select.styled";
import { IconButton, Button } from "../styled/Button.styled";
import { Card } from "../styled/Dashboard.styled";
import Delete from "../icons/Delete";
import ChevronUp from "../icons/ChevronUp";
import ChevronDown from "../icons/ChevronDown";
import { Label, Input } from '../styled/Form.styled';
import Edit from '../icons/Edit';

const NEW_COURSE_ID = 'new';

class ManageModules extends React.Component {
  constructor(props) {
    super(props);

    const search = new URLSearchParams(this.props.location.search);

    this.state = {
      loading: false,
      modules: [],
      chapters: [],
      filters: [],
      selectedModule: (search.has('id') && search.get('id') !== NEW_COURSE_ID) ? search.get('id') : '',
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      await this.fetchModules();
      await this.fetchChapters();
      await this.fetchFilters();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  async fetchFilters() {
    try {
      const { docs } = await firestore().collection('filters').get();

      const filters = docs.map(d => ({
        id: d.id,
        ...d.data()
      }));

      this.setState((state) => ({
        filters
      }));
    } catch (error) {
      console.error(error);
      await toast.error('Error fetching filters - please check the developer console');
    }
  }


  get hasUnsavedNewModule() {
    return this.state.modules.some(mod => mod.id === NEW_COURSE_ID);
  }

  async fetchModules() {
    try {
      const { docs } = await firestore().collection('modules').get();

      const modules = docs.map(d => ({
        id: d.id,
        ...d.data()
      }));

      this.setState({
        modules,
      }, () => {
        if ((!this.state.selectedModule) && modules.length === 1) {
          this.handleExpand(modules[0].id)
        }
      });
    } catch (error) {
      console.error(error);
      await toast.error('Error fetching modules - please check the developer console');
    }
  }

  async fetchChapters() {
    try {
      const { docs } = await firestore().collection('chapters').get();

      const chapters = docs.map(d => ({
        id: d.id,
        ...d.data()
      }));

      this.setState((state) => ({
        chapters,
        modules: state.modules.map((mod) => ({
          ...mod,
          chapters: mod.chapters.map((id) => chapters.find((chapter) => chapter.id === id)),
        }))
      }));
    } catch (error) {
      console.error(error);
      await toast.error('Error fetching chapters - please check the developer console');
    }
  }

  handleExpand(id) {
    const { history, location } = this.props;
    const { selectedModule } = this.state;

    history.replace({
      pathname: location.pathname,
      search: selectedModule === id ? '' : `id=${id}`,
    });

    this.setState((state) => ({
      selectedModule: state.selectedModule === id ? '' : id,
    }));
  }

  handleChapterSelect = (event) => {
    const id = event.target.value;
    if (id) {
      this.setState((state) => ({
        modules: state.modules.map((mod) => (
          mod.id === state.selectedModule
            ? { ...mod, chapters: [...mod.chapters, state.chapters.find(chapter => chapter.id === id)] }
            : mod
        ))
      }))
    }
  }

  handleChapterDelete = (id) => {
    this.setState((state) => ({
      modules: state.modules.map((mod) => (
        mod.id === state.selectedModule
          ? { ...mod, chapters: mod.chapters.filter(chapter => chapter.id !== id) }
          : mod
      ))
    }))
  }

  handleChapterUp = (i) => {
    this.setState((state) => ({
      modules: state.modules.map((mod) => (
        mod.id === state.selectedModule
          ? {
            ...mod,
            chapters: arrayMove(mod.chapters, i, i - 1),
          }
          : mod
      ))
    }))
  }

  handleChapterDown = (i) => {
    this.setState((state) => ({
      modules: state.modules.map((mod) => (
        mod.id === state.selectedModule
          ? {
            ...mod,
            chapters: arrayMove(mod.chapters, i, i + 1),
          }
          : mod
      ))
    }))
  }

  handleInputChange = (event) => {
    const { value, name, checked, type } = event.target;

    let v = value;

    switch(type){
      case "number":
        if (v !== ""){
          v = parseInt(value, 10);
          const {min, max} = event.target;
          if (min && max){
            if (value < min) v = min;
            if(value > max) v = max;
          }
        }else{
          v = null;
        }
        break;
      case "checkbox":
        v = checked;
        break;
      default:
        break;
    }

    this.setState((state) => ({
      modules: state.modules.map((mod) => (mod.id === state.selectedModule
        ? { ...mod, [name]: v }
        : mod
      ))
    }))
  }

  handleSubmit = async (modId) => {
    try {
      const { id, ...mod } = this.state.modules.find(m => m.id === modId);

      const payload = {
        ...mod,
        chapters: mod.chapters.map(m => m.id),
      };

      if (modId === NEW_COURSE_ID) {
        const doc = await firestore().collection('modules').add(payload);

        await toast.success('New module added!');

        this.setState((state) => ({
          modules: state.modules.map(mod => mod.id === NEW_COURSE_ID
            ? { ...mod, id: doc.id }
            : mod
          )
        }), () => {
          this.handleExpand(doc.id);
        });
      } else {
        await firestore().collection('modules').doc(modId).update(payload);
        await toast.success('Module updated')
      }
    } catch (error) {
      console.error(error);
      await toast.error('Error saving module - please check the developer console');
    }
  }

  handleAdd = () => {
    this.setState((state) => ({
      modules: [...state.modules, {
        id: NEW_COURSE_ID,
        name: 'NEW MODULE!',
        slug: 'new-module',
        duration: 0,
        remote: false,
        hidden: false,
        chapters: [],
      }]
    }), () => {
      this.handleExpand(NEW_COURSE_ID);
    });
  }

  handleDelete = async (id) => {
    const confirm = window.confirm('Are you sure you want to delete this module permanently?');
    if (confirm) {
      try {
        if (id !== NEW_COURSE_ID) {
          await firestore().collection('modules').doc(id).delete();
          await toast.success('Module deleted!');
        }

        this.setState((state) => ({
          modules: state.modules.filter(mod => mod.id !== id),
        }), () => {
          this.handleExpand(id);
        });

      } catch (error) {
        console.error(error);
        await toast.error('Error deleting module - please check the developer console');
      }
    }
  }

  handleChapterEditClick = (id) => {
    this.props.history.push({
      pathname: '/admin/chapters',
      search: `?id=${id}`,
    });
  }

  handleMultiSelectAdd = (event) => {
    const {name, value} = event.target;
    this.setState((state) => ({
      modules: state.modules.map((module) => (module.id === state.selectedModule
        ? { ...module, [name]: [...module[name] || [], value] }
        : module
      ))
    }));
  }

  handleMultiSelectRemove = (id, value) => {
    this.setState((state) => ({
      modules: state.modules.map((module) => (
        module.id === state.selectedModule
          ? { ...module, [id]: module[id].filter(option => option !== value) }
          : module
      ))
    }))
  }



  render() {
    const {
      loading,
      modules,
      chapters,
      filters,
      selectedModule,
    } = this.state;

    if (loading) {
      return <Card to="#" onClick={(e) => e.preventDefault()} loading>Loading...</Card>;
    }

    return (
      <Wrap>
        <Inner>
          <Title>Modules</Title>
          <Box>
            <List>
              {modules.map((mod) => (
                <ChapterBox key={mod.id}>
                  <ChapterName>
                    {mod.name}
                    <ExpandIcon
                      selected={selectedModule === mod.id}
                      onClick={() => this.handleExpand(mod.id)}
                    />
                  </ChapterName>
                  <ExpandPanel open={selectedModule === mod.id}>
                    <PaddedContainer>
                      <PaddedContainer>
                        <Label>Name:</Label>
                        <Input
                          type="text"
                          name="name"
                          value={mod.name}
                          onChange={this.handleInputChange}
                        />
                      </PaddedContainer>
                      <PaddedContainer>
                        <Label>Slug:</Label>
                        <Input
                          type="text"
                          name="slug"
                          value={mod.slug}
                          onChange={this.handleInputChange}
                        />
                      </PaddedContainer>
                      <PaddedContainer>
                        <Label>Duration:</Label>
                        <Input
                          type="number"
                          name="duration"
                          value={mod.duration}
                          onChange={this.handleInputChange}
                        />
                      </PaddedContainer>
                      <PaddedContainer>
                        <Label>Remote:</Label>
                        <Input
                          type="checkbox"
                          name="remote"
                          checked={mod.remote}
                          onChange={this.handleInputChange}
                        />
                      </PaddedContainer>
                      <PaddedContainer>
                        <Label>Hide from course view:</Label>
                        <Input
                          type="checkbox"
                          name="hidden"
                          checked={mod.hidden}
                          onChange={this.handleInputChange}
                        />
                      </PaddedContainer>
                      <ChapterName>Chapters:</ChapterName>
                      <List>
                        {mod.chapters.map((chapter, i, chapters) => (
                          <ListItem key={chapter.id}>
                            {chapter.name}
                            <Controls>
                              {i === 0 ? null : (
                                <IconButton
                                  title="Move Up"
                                  onClick={() => this.handleChapterUp(i)}
                                >
                                  <ChevronUp />
                                </IconButton>
                              )}
                              {i === chapters.length - 1 ? null : (
                                <IconButton
                                  title="Move Down"
                                  onClick={() => this.handleChapterDown(i)}
                                >
                                  <ChevronDown />
                                </IconButton>
                              )}
                              <IconButton
                                title="Edit"
                                onClick={() => this.handleChapterEditClick(chapter.id)}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                title="Remove"
                                onClick={() => this.handleChapterDelete(chapter.id)}
                              >
                                <Delete />
                              </IconButton>
                            </Controls>
                          </ListItem>
                        ))}
                      </List>
                      <PaddedContainer>
                        <Select
                          label="Add Chapter:"
                          onChange={this.handleChapterSelect}
                        >
                          <option key="default" value="">Select Chapter</option>
                          {chapters
                            .filter((chapter) => !modules.some(m => m.chapters.some(c => c.id === chapter.id)))
                            .map((chapter) => (
                              <option key={chapter.id} value={chapter.id}>
                                {chapter.name}
                              </option>
                            ))
                          }
                        </Select>
                      </PaddedContainer>
                      {
                        (!mod.hidden) && filters.map(filter => {

                          let inputOptions = {...filter.options};

                          delete inputOptions.step;

                          let Comp;
                          let children = null;
                          let PreComp = null;

                          switch (filter.type){
                            case "multiselect":
                              Comp = Select;
                              inputOptions.label = `Add ${filter.name}:`;
                              inputOptions.value = "";

                              children = [<option key="default" value="">Add a {filter.name}</option>];
                              children.push(filter.options.map(option => {
                                
                                if (mod[filter.id] && mod[filter.id].includes(option)) return null;

                                return (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                );
                              }
                              ));
                              
                              const opts = mod[filter.id] || [];
                              PreComp = (<List>
                                {opts.length > 0 ? opts.map((filterOption) => (
                                  <ListItem key={filterOption}>
                                    {filterOption}
                                    <Controls>
                                      <IconButton
                                        title="Remove"
                                        onClick={() => this.handleMultiSelectRemove(filter.id, filterOption)}
                                      >
                                        <Delete />
                                      </IconButton>
                                    </Controls>
                                  </ListItem>
                                )) : <ListItem key="no-filter">No {filter.name} added</ListItem>}
                              </List>);

                              inputOptions.onChange = this.handleMultiSelectAdd;

                              break;
                            case "select":
                              Comp = Select;
                              children = [<option key="default" value="">Select {filter.name}</option>];

                              children.push(filter.options.map(option => 
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ));
                              inputOptions.onChange = this.handleInputChange;

                              break;
                            default:
                              Comp = Input;
                              inputOptions.placeholder = `Enter ${filter.name}`;
                              inputOptions.onChange = this.handleInputChange;
                              break;
                          }
                          
                          return (
                            <div key={filter.name}>
                              <ChapterName>{filter.name}</ChapterName>
                              {PreComp}
                              <PaddedContainer key={filter.id}>
                                <Comp name={filter.id} value={mod[filter.id]} type={filter.type} {...inputOptions}>{children}</Comp>
                              </PaddedContainer>
                            </div>
                          )
                         }) 
                        }

                    </PaddedContainer>
                    <PaddedContainer>
                      <Button
                        color="cardinal"
                        onClick={() => this.handleSubmit(mod.id)}
                      >
                        Save
                      </Button>
                      <Button
                        color="orangeSoda"
                        onClick={() => this.handleDelete(mod.id)}
                      >
                        Delete
                      </Button>
                    </PaddedContainer>
                  </ExpandPanel>
                </ChapterBox>
              ))}
            </List>
            <PaddedContainer>
              <Button
                color="cardinal"
                onClick={this.handleAdd}
                disabled={this.hasUnsavedNewModule}
              >
                Add Module
              </Button>
            </PaddedContainer>
          </Box>
        </Inner>
      </Wrap>
    );
  }
}

export default ManageModules;
