import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Formik } from "formik";
import StripeCheckout from "react-stripe-checkout";
import * as Yup from "yup";
import { setUser as _setUser } from "../redux/auth/auth.slice";
import {
  Wrap,
  Inner,
  Form,
  FormGroup,
  Label,
  Input,
  Error as FormError
} from "./styled/Login.styled";
import { Title } from "./styled/Title.styled";
import { Button } from "./styled/Button.styled";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import { firestore, auth } from "../firebase";
import { flattenFirebaseUser } from "../helpers/auth";

const EnrolWrap = styled(Wrap)`
  height: auto;
`;

const EnrolInner = styled(Inner)`
  max-width: 600px;
`;

const EnrolSchema = Yup.object().shape({
  cohort: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  emergencyContactName: Yup.string().required("Required"),
  emergencyContactPhone: Yup.string().required("Required"),
  reach: Yup.string().required("Required"),
  acceptedTerms: Yup.bool().oneOf(
    [true],
    "Please read and accept the terms and conditions"
  )
});

export const getRemainingAmount = user => {
  const { total, payments } = user.course;

  const amountPaid = payments.reduce(
    (total, payment) => total + payment.amount,
    0
  );

  return total - amountPaid;
};

export const getMinimumPaymentAmount = user => {
  const { paymentDueDate, payments } = user.course;
  const remainingAmount = getRemainingAmount(user);

  if (payments.length) {
    return 0;
  }

  const COURSE_DEPOSIT = 50000;

  return new Date() >= moment.unix(paymentDueDate.seconds).toDate()
    ? remainingAmount
    : COURSE_DEPOSIT;
};

class Payment extends Component {
  state = {
    paymentAmount: getMinimumPaymentAmount(this.props.user),
    paymentProcessing: false
  };

  handlePurchase = async stripeToken => {
    const { paymentAmount } = this.state;
    const { user, setUser } = this.props;

    this.setState({ paymentProcessing: true });

    try {
      const idToken = await auth().currentUser.getIdToken(true);

      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_STRIPE_ENDPOINT}?stripeToken=${
          stripeToken.id
        }&paymentAmount=${paymentAmount}&userId=${user.uid}`,
        headers: {
          authorization: `Bearer ${idToken}`,
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*"
        }
      });

      const profileDoc = await firestore()
        .doc(`users/${user.uid}`)
        .get();

      setUser(
        flattenFirebaseUser({
          ...user,
          ...profileDoc.data()
        })
      );

      this.setState({ paymentProcessing: false });

      toast.success("Payment processed successfully");
    } catch (ex) {
      console.log(ex);
      toast.error(ex.message);
    }
  };

  render() {
    const { user } = this.props;
    const { paymentAmount, paymentProcessing } = this.state;

    return (
      <EnrolWrap>
        <EnrolInner>
          <Title>Payment</Title>
          <Formik
            initialValues={{
              paymentAmount: getMinimumPaymentAmount(user) / 100
            }}
            validationSchema={EnrolSchema}
            onSubmit={this.handleSubmit}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                {paymentProcessing ? (
                  "Payment processing. Please wait..."
                ) : (
                  <Fragment>
                    <FormGroup>
                      <Label>Total Course Fees</Label>£
                      {(user.course.total / 100).toFixed(2)}
                    </FormGroup>
                    <FormGroup>
                      <Label>Balance Outstanding</Label>£
                      {(getRemainingAmount(user) / 100).toFixed(2)}
                    </FormGroup>
                    <FormGroup>
                      <Label>Balance Due By</Label>
                      {moment
                        .unix(user.course.paymentDueDate.seconds)
                        .format("Do MMM YYYY")}
                    </FormGroup>
                    <FormGroup>
                      <Label>Minimum Payment Amount</Label>£
                      {(getMinimumPaymentAmount(user) / 100).toFixed(2)}
                    </FormGroup>
                    <FormGroup>
                      <Label>Payment Amount</Label>
                      <Input
                        name="paymentAmount"
                        type="number"
                        step="1"
                        style={{ width: 150 }}
                        onChange={e => {
                          this.setState({
                            paymentAmount: parseInt(e.target.value, 10) * 100
                          });
                          setFieldValue("paymentAmount", e.target.value);
                        }}
                        min={getMinimumPaymentAmount(user) / 100}
                        max={getRemainingAmount(user) / 100}
                      />
                      {errors.paymentAmount && touched.paymentAmount ? (
                        <FormError>{errors.paymentAmount}</FormError>
                      ) : null}
                    </FormGroup>
                    <StripeCheckout
                      name="Manchester Codes"
                      token={this.handlePurchase}
                      image="https://mcrcodes.s3.eu-west-2.amazonaws.com/MCR_Codes_M_CMYK_slack.png"
                      amount={paymentAmount}
                      currency="GBP"
                      email={user.email}
                      zipcode={true}
                      stripeKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                    >
                      <Button type="primary">Checkout</Button>
                    </StripeCheckout>
                  </Fragment>
                )}
              </Form>
            )}
          </Formik>
        </EnrolInner>
      </EnrolWrap>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

const mapDispatchToProps = {
  setUser: _setUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment);
