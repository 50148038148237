import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify'
import { auth } from '../../firebase';
import { Card } from "../styled/Dashboard.styled";
import { List, ListItem, Wrap, Inner, Box, PaddedContainer } from "../styled/AdminChapters.styled";
import { Title } from "../styled/Title.styled";
import { Label, Input } from '../styled/Form.styled';
import { Button } from '../styled/Button.styled';

class AdminUsers extends React.Component {
  state = {
    loaded: false,
    submitting: false,
    users: [],
    email: '',
  }

  async componentDidMount() {
    try {
      const users = await this.fetchUsers();

      this.setState({ users, loaded: true });
    } catch (error) {
      console.error(error);
      await toast.error('Error loading user data');
    }
  }

  async fetchUsers() {
    const idToken = await auth().currentUser.getIdToken(true);

    const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/getAdminUsers`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return response.data;
  }

  handleChange = (event) => {
    const { target: { value } } = event;

    this.setState({ email: value });
  }

  handleSubmit = () => {
    const { email } = this.state;

    this.setState({ submitting: true }, async () => {
      try {
        const idToken = await auth().currentUser.getIdToken(true);

        const { data } = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/makeAdmin`, { email }, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });

        this.setState((state) => ({
          users: state.users.concat(data),
          email: '',
        }));

        await toast.success('New admin user added successfully');
      } catch (error) {
        console.error(error);
        let message = 'Error adding admin privileges to user.'

        if (error.response && error.response.status === 404) {
          message = 'A user with that email address doesn\'t exist.';
        } else if (error.response && error.response.status === 403) {
          message = 'The requested user has not verified their email address.';
        }

        await toast.error(message);
      }

      this.setState({ submitting: false });
    });
  }

  render() {
    const { loaded, users, email, submitting } = this.state;

    if (!loaded) {
      return <Card to="#" onClick={(e) => e.preventDefault()} loading>Loading...</Card>;
    }

    const sortedUsers = users.sort((a, b) => {
      const aName = a.firstName.toLowerCase();
      const bName = b.firstName.toLowerCase();

      if (aName < bName) { 
        return -1; 
      }
      if (aName > bName) { 
        return 1; 
      }

      return 0;
    });

    return (
      <Wrap>
        <Inner>
          <Title>Admin Users</Title>
          <Box>
            <List>
              {sortedUsers.map((user) => (
                <ListItem key={user.uid}>{user.firstName ? `${user.firstName} ${user.lastName} (${user.email})` : (user.email || user.uid)}</ListItem>
              ))}
            </List>
          </Box>
          <Box>
            <PaddedContainer>
              <Label>
                Add Admin:
                <Input
                  type="text"
                  placeholder="Email Address"
                  value={email}
                  onChange={this.handleChange}
                />
              </Label>
              <Button
                color="cardinal"
                onClick={this.handleSubmit}
                disabled={submitting}
              >
                Add
              </Button>
            </PaddedContainer>
          </Box>
        </Inner>
      </Wrap>
    );
  }
}

export default AdminUsers;
