import { createGlobalStyle } from "styled-components";
import { color } from "./theme";

export default createGlobalStyle`
  html {
    font-size: 62.5%;
  }

  body {
    font-size: 1.6rem;
    background-color: ${({ theme }) => color(theme, "whiteSmoke")};
    color: #222222;
  }

  html, body, #root, .App {
    height: 100%;
  }

  p {
    margin: 0 0 1.5rem;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }
  }

  * {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
