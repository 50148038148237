import React from "react";
import Markdown from "markdown-to-jsx";
import EmojiConverter from "emoji-js";
import { ContentBlock } from "../styled/Guide.styled";
import { Code, YouTube } from "../guide-components";
import { MarkdownStyles } from "../styled/GHMarkdown.styled";

const Guide = ({ page }) => {
  const emojiConverter = new EmojiConverter();
  const content = emojiConverter.replace_colons(page.content || "");

  return (
    <ContentBlock>
      <MarkdownStyles>
        <Markdown
          options={{
            overrides: {
              pre: {
                component: Code
              },
              YouTube: {
                component: YouTube
              },
              a: {
                props: {
                  target: "_blank"
                }
              }
            }
          }}
        >
          {content || ""}
        </Markdown>
      </MarkdownStyles>
    </ContentBlock>
  );
};

export default Guide;
