import React from 'react';
import { Wrap, Inner, Card } from "../styled/Dashboard.styled";
import adminRoutes from './routes';

const colors = [
  'middleBlue',
  'seaGreen',
  'cameoPink',
  'yellow',
  'orangeSoda',
];

const AdminDashboard = () => (
  <Wrap>
    <Inner>
      {adminRoutes.filter((route) => route.showOnDashboard).map((route, i, routes) => (
        <Card
          key={route.path}
          to={route.path}
          color={colors[i % routes.length]}
          height="auto"
        >
          <h2>{route.title}</h2>
        </Card>
      ))}
    </Inner>
  </Wrap>
);

export default AdminDashboard;
