import React from 'react';
import styled from "styled-components";
import ChevronDown from "../icons/ChevronDown";
import { IconButton } from './Button.styled';
import { darken } from 'polished';

export const Wrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.a}px) {
    padding: 5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const Inner = styled.div`
  min-width: 320px;
  max-width: 900px;
  width: 100%;
`;

export const Box = styled.div`
  background-color: white;
  padding: 1.25rem 0;
`;

export const ChapterBox = styled.div`
  padding: 1.25rem 2.5rem;
  text-decoration: none;
  color: inherit;
  width: 100%;
`;

export const ChapterName = styled.div`
  position: relative;
  padding: 1.25rem 2.5rem;
  border-bottom: 1px solid #eee;
  font-weight: bold;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    cursor: pointer;
    margin: 0 0.5rem;
  }
`;

export const List = styled.div`
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 2.5rem;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.5s ease-in-out;
  width: 100%;

  span {
    display: flex;
    width: 3rem;
    height: 3rem;
    border: 1px solid #ddd;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #ccc;
    margin-right: 2rem;
    background-color: white;
  }

  &:hover {
    background-color: ${darken(0.07, '#f4f4f4')};
  }
`;

const ExpandButton = styled(IconButton)`
  float: right;
  height: 24px;
  transition: 0.2s linear;
  transform: rotate(${({ selected }) => selected ? '180' : '0'}deg);
  vertical-align: middle;
`;

export const ExpandIcon = ({ selected, ...props }) => (
  <ExpandButton selected={selected} {...props}>
    <ChevronDown />
  </ExpandButton>
);

export const ExpandPanel = styled.div`
  background-color: #f4f4f4;
  height: ${({ open }) => open ? 'auto' : '0'};
  overflow-y: hidden;
`

export const PaddedContainer = styled.div`
  padding: 1.25rem 2.5rem;
`;
