export const flattenFirebaseUser = user => ({
  uid: user.uid,
  email: user.email,
  emailVerified: user.emailVerified,
  firstName: user.firstName,
  lastName: user.lastName,
  dob: user.dob,
  eligible: user.eligible,
  enrolled: user.enrolled,
  paid: user.paid,
  course: user.course,
});
