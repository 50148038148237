import React from "react";
import { Field } from 'formik';
import styled from "styled-components";

export const Row = styled.div`
  margin-bottom: 2.5rem;
`;

export const Form = styled.form`
  background-color: white;
  padding: 2.5rem;
`;

export const Label = styled.label`
  display: block;
  font-weight: bold;
  font-size: inherit;
  margin: 0 0 1rem;
`;


const RadioLabel = styled(Label)`
  flex: 1;
  font-weight: normal;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.4;
  position: relative;
  padding-left: 3.5rem;
`;

const RadioInput = styled(Field)`
  -webkit-appearance: none;
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  position: absolute;
  left: 0;
  cursor: pointer;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.whiteSmoke};
  margin: 0;
  outline: none;

  &:checked {
    background-color: ${({ theme }) => theme.colors.cardinal};
  }

  &:before,
  &:checked:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 2rem;
    text-align: center;
    color: #fff;
  }

  &:checked:before {
    content: "✔";
  }
`;

export const Radio = ({ label, ...props }) => (
  <div>
    <RadioLabel>
      <RadioInput {...props} type="radio" />
      {label}
    </RadioLabel>
  </div>
);


export const Input = styled.input`
  border: 0;
  font-family: inherit;
  font-size: inherit;
  border-bottom: 2px solid ${({ theme }) => theme.colors.whiteSmoke};
  padding: 1rem 0;
  display: block;
  width: 100%;
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: ${({ theme }) => theme.colors.cardinal};
  }
`;

export const TextArea = styled.textarea`
  border: 0;
  font-family: inherit;
  font-size: inherit;
  border: 2px solid ${({ theme }) => theme.colors.whiteSmoke};
  padding: 1rem;
  display: block;
  width: 100%;
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: ${({ theme }) => theme.colors.cardinal};
  }
`;

export const Panel = styled.div`
  background-color: #f4f4f4;
`;
