import styled from "styled-components";

export const Wrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.b}px) {
    display: flex;
    flex: 1;
  }
`;

export const GuideOuter = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.b}px) {
    width: 500px;
    display: flex;
  }
`;

export const IdeOuter = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.b}px) {
    flex: 1;
  }
`;
