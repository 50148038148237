import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
  Wrap,
  Inner,
  Form,
  FormGroup,
  Label,
  Input,
  Divider,
  Error,
  FormLink
} from "./styled/Login.styled";
import { Title } from "./styled/Title.styled";
import { Button } from "./styled/Button.styled";
import { doLogin as _doLogin } from "../redux/auth/auth.slice";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("Required"),
  password: Yup.string().required("Required")
});

class Login extends Component {
  handleLogin = values => {
    const { doLogin } = this.props;

    doLogin(values);
  };

  render() {
    const { user } = this.props;

    if (user) {
      return <Redirect to="/" />;
    }

    return (
      <Wrap>
        <Inner>
          <Title>Login</Title>
          <Formik
            onSubmit={this.handleLogin}
            validationSchema={LoginSchema}
            initialValues={{ email: "", password: "" }}
          >
            {({ errors, touched }) => (
              <Form>
                <FormGroup>
                  <Label>Email</Label>
                  <Input name="email" type="email" autoFocus />
                  {errors.email && touched.email ? (
                    <Error>{errors.email}</Error>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <Input name="password" type="password" />
                  {errors.password && touched.password ? (
                    <Error>{errors.password}</Error>
                  ) : null}
                </FormGroup>
                <Button type="submit" stretch color="middleBlue">
                  Login
                </Button>
                <FormLink to="/forgot-password">Forgot password?</FormLink>
                <Divider>
                  <span>or</span>
                </Divider>
                <Button as={Link} to="/create-account" color="seaGreen" stretch>
                  Create Account
                </Button>
              </Form>
            )}
          </Formik>
        </Inner>
      </Wrap>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

const mapDispatchToProps = {
  doLogin: _doLogin
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
