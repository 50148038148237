import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { doEmailVerification as _doEmailVerification } from "../redux/auth/auth.slice";
import qs from "qs";

class Auth extends Component {
  componentDidMount() {
    const { location, doEmailVerification, history } = this.props;

    const { mode, oobCode } = qs.parse(location.search, {
      ignoreQueryPrefix: true
    });

    switch (mode) {
      case "verifyEmail":
        doEmailVerification(oobCode);
        break;
      case "resetPassword":
        history.push(`/reset-password?oobCode=${oobCode}`);
        break;
      default:
        break;
    }
  }

  render() {
    const { user } = this.props;

    if (user && user.emailVerified) {
      return <Redirect to="/" />;
    }

    return null;
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

const mapDispatchToProps = {
  doEmailVerification: _doEmailVerification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
