import React from 'react';
import { toast } from 'react-toastify'
import styled from 'styled-components';
import firestore from '../../firebase';
import { Wrap, Inner, Box, PaddedContainer } from "../styled/AdminChapters.styled";
import { Title } from "../styled/Title.styled";
import { Card, CardGrid, GridCard } from "../styled/Dashboard.styled";
import Select from '../styled/Select.styled';
import { Span as StatusSpan } from '../styled/StatusText.styled';
import Modal from '../Modal';

const paidAmount = (payments) => payments.reduce((total, payment) => total + payment.amount, 0);

const studentPaymentStatus = (student) => {
  if (!student.paid || !student.course.payments) {
    return { status: 'error', message: 'Awaiting deposit' };
  }

  const balance = student.course.total - paidAmount(student.course.payments);

  if (balance > 0) {
    return { status: 'warning', message: `Outstanding balance: £${balance / 100}` };
  }

  if (student.finance) {
    return { status: 'success', message: 'Finance agreement set up' };
  }

  return { status: 'success', message: 'Balance settled' };
};

const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: 2.5rem;
`;

class StudentDashboard extends React.Component {
  state = {
    loaded: false,
    cohorts: [],
    courses: [],
    students: [],
    selectedStudent: '',
  }

  async componentDidMount() {
    try {
      const [cohorts, courses] = await Promise.all([
        this.fetchCohorts(),
        this.fetchCourses(),
      ]);

      let students = [];

      if (this.selectedCohort && cohorts.some((cohort) => cohort.id === this.selectedCohort)) {
        students = await this.fetchCohortStudents(this.selectedCohort);
      }

      this.setState(() => ({
        cohorts,
        courses,
        students,
      }));
    } catch (error) {
      await toast.error(error.message);
    }

    this.setState({ loaded: true });
  }

  get selectedCohort() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search)

    if (params.has('cohort')) {
      return params.get('cohort');
    }

    return '';
  }

  get selectedStudent() {
    const isSelected = (student) => student.id === this.state.selectedStudent;

    if (this.state.students.some(isSelected)) {
      return this.state.students.find(isSelected);
    }

    return null;
  }

  async fetchCohorts() {
    try {
      const { docs } = await firestore().collection('cohorts').orderBy('startDate', 'desc').get();

      return docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error(error);
      throw new Error('Unable to load cohorts list');
    }
  }

  async fetchCourses() {
    try {
      const { docs } = await firestore().collection('courses').get();

      return docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error(error);
      throw new Error('Unable to load course list');
    }
  }

  async fetchCohortStudents(cohort) {
    if (cohort === '') {
      return [];
    }

    try {
      const { docs } = await firestore().collection('users')
        .where('cohort', '==', cohort)
        .where('enrolled', '==', true)
        .get();

      return docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error(error);
      throw new Error('Unable to load students');
    }
  }

  handleCohortChange = (e) => {
    const{ history, location } = this.props;
    const selectedCohort = e.target.value;

    history.replace(`${location.pathname}${selectedCohort ? `?cohort=${selectedCohort}` : ''}`);

    this.fetchCohortStudents(selectedCohort).then((students) => {
      this.setState({ students, selectedStudent: '' });
    });
  }

  handleStudentSelect = (id) => {
    this.setState({ selectedStudent: id });
  }

  handleModalClose = () => {
    this.setState({ selectedStudent: '' });
  }

  renderStudentModal = () => (
    <Wrap>
      <Inner>
        <Title block>{this.selectedStudent.firstName} {this.selectedStudent.lastName}</Title>
        <Box>
          <PaddedContainer>
            <ModalGrid>
              <div>
                <h4>Course Information</h4>
                <p>Course: {this.state.courses.find(c => c.id === this.selectedStudent.course.id).name}</p>
                <p>Cohort: {this.state.cohorts.find(c => c.id === this.selectedStudent.cohort).name}</p>
              </div>

              <div>
                <h4>Personal Information</h4>
                <p>DOB: {this.selectedStudent.dob}</p>
                <p>Phone: {this.selectedStudent.phone}</p>
                <p>Emergency Contact: {this.selectedStudent.emergencyContactName} ({this.selectedStudent.emergencyContactPhone})</p>
              </div>

              <div>
                <h4>Payment Info</h4>
                <p>Course Price: £{this.selectedStudent.course.total / 100}</p>
                <p>Amount Paid: £{paidAmount(this.selectedStudent.course.payments) / 100}</p>
                <p>Amount Outstanding: £{(this.selectedStudent.course.total - paidAmount(this.selectedStudent.course.payments)) / 100}</p>
                <p>Payments Made: {this.selectedStudent.course.payments.length}</p>
                <p>Payment Due Date: {this.selectedStudent.course.paymentDueDate.toDate().toLocaleDateString("en-GB")}</p>
                <p>Women's Discount: {this.selectedStudent.womensDiscount.toString()}</p>
              </div>

              <div>
                <h4>Miscellaneous</h4>
                <p>Accepted Terms: {this.selectedStudent.acceptedTerms.toString()}</p>
                <p>Health Info: {this.selectedStudent.health}</p>
                <p>Needs laptop: {this.selectedStudent.laptop.toString()}</p>
                <p>Ubuntu support: {this.selectedStudent.ubuntuSupport.toString()}</p>
                <p>Reach: {this.selectedStudent.reach}</p>
              </div>
            </ModalGrid>
          </PaddedContainer>
        </Box>
      </Inner>
    </Wrap>
  );

  render() {
    const { loaded, cohorts, students } = this.state;

    if (!loaded) {
      return <Card to="#" onClick={(e) => e.preventDefault()} loading>Loading...</Card>;
    }

    return (
      <Wrap>
        <Inner>
          <Title>Student Dashboard</Title>
          <Box>
            <PaddedContainer>
              <Select
                label="Cohort"
                value={this.selectedCohort}
                onChange={this.handleCohortChange}
              >
                <option key="default" value="">Select Cohort</option>
                {cohorts.map((cohort) => (
                  <option key={cohort.id} value={cohort.id}>{cohort.name}</option>
                ))}
              </Select>
            </PaddedContainer>
          </Box>
          <CardGrid>
            {students.map((student) => {
              const { status, message } = studentPaymentStatus(student);
              return (
                <GridCard key={student.id} onClick={() => this.handleStudentSelect(student.id)}>
                  <h4>{student.firstName} {student.lastName}</h4>
                  <p><StatusSpan status={status}>{message}</StatusSpan></p>
                </GridCard>
              )
            })}
          </CardGrid>
        </Inner>
        <Modal
          open={this.selectedStudent}
          onOverlayClick={this.handleModalClose}
          onEscapePress={this.handleModalClose}
        >
          {this.renderStudentModal}
        </Modal>
      </Wrap>
    );
  }
}

export default StudentDashboard;
