export const theme = {
  colors: {
    cardinal: "#313e48",
    manatee: "#96a5a9",
    whiteSmoke: "#f4f4f4",
    yellow: "#f7d40a",
    orangeSoda: "#f1583e",
    cameoPink: "#eeb6d4",
    seaGreen: "#34a061",
    middleBlue: "#69cce3",
    blue: "#2b3792",
    white: "#fff",
    black: "#000",
    warning: '#f7d40a',
    error: '#f1583e',
    success: '#34a061',
  },
  breakpoints: {
    a: 768,
    b: 1024
  }
};

export const color = ({ colors }, colorName) => colors[colorName];
