import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Wrap,
  Inner,
  Form,
  FormGroup,
  Label,
  Input,
  Error
} from "./styled/Login.styled";
import { Title } from "./styled/Title.styled";
import { Button } from "./styled/Button.styled";
import { doEditProfile as _doEditProfile } from "../redux/auth/auth.slice";

const EditProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  dob: Yup.string().test("dob", "You must be at least 18 to sign up", value => {
    return moment().diff(moment(value), "years") >= 18;
  })
});

class MoreInfo extends Component {
  handleEditProfile = values => {
    const { doEditProfile } = this.props;

    doEditProfile(values);
  };

  render() {
    const { match, user } = this.props;

    if (match.path !== "/edit-profile" && user.firstName) {
      return <Redirect to="/" />;
    }

    return (
      <Wrap>
        <Inner>
          <Title>Complete Profile</Title>
          <Formik
            onSubmit={this.handleEditProfile}
            validationSchema={EditProfileSchema}
            initialValues={{ firstName: "", lastName: "", dob: "" }}
          >
            {({ errors, touched }) => (
              <Form>
                <FormGroup>
                  <Label>First Name</Label>
                  <Input name="firstName" type="text" autoFocus />
                  {errors.firstName && touched.firstName ? (
                    <Error>{errors.firstName}</Error>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>Last Name</Label>
                  <Input name="lastName" type="text" />
                  {errors.lastName && touched.lastName ? (
                    <Error>{errors.lastName}</Error>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>Date of Birth</Label>
                  <Input name="dob" type="date" />
                  {errors.dob && touched.dob ? (
                    <Error>{errors.dob}</Error>
                  ) : null}
                </FormGroup>
                <Button type="submit" stretch color="seaGreen">
                  Complete Registration
                </Button>
              </Form>
            )}
          </Formik>
        </Inner>
      </Wrap>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

const mapDispatchToProps = {
  doEditProfile: _doEditProfile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoreInfo);
