import * as React from 'react';
import styled from 'styled-components';
import { ChevronDown, Document } from "./icons";

const IconPickerWrap = styled.div`
  position: relative;
`;

const IconListWrap = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.manatee};
  bottom: 0;
  left: 0;
  position: absolute;
  visibility: ${({ isOpen }) => isOpen ? 'initial' : 'hidden'};
  z-index: 9999;
`;

const IconListInner = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  overflow-y: scroll;
  max-height: 300px;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.manatee};
  border-left: none;
  cursor: pointer;
  outline: none;
  padding: 1.25rem;

  &:first-of-type {
    border-left: 2px solid ${({ theme }) => theme.colors.manatee};
  }
`;

const ExpandButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.whiteSmoke};
`;

const IconItem = styled(Button)`
  border: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.whiteSmoke};
  }

  &:first-of-type {
    border-left: none;
  }
`;

class IconPicker extends React.Component {
  state = {
    isOpen: false,
  }

  get selectedIcon() {
    const { icons, value } = this.props;
    if (icons.some(icon => icon.name === value)) {
      return icons.find(icon => icon.name === value);
    }
    return { name: 'Document', Icon: Document };
  }

  handleOpenClick = () => {
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  }

  handleBlur = (event) => {
    const { relatedTarget } = event;
    if (!(relatedTarget && relatedTarget.classList.contains('icon-picker-select-button'))) {
      this.setState({
        isOpen: false,
      });
    }
  }

  handleChange = (name) => (event) => {
    const { onChange } = this.props;
    event.preventDefault();
    onChange({ target: { name: this.props.name, value: name } });
    this.setState({
      isOpen: false,
    });
  }

  renderButtons() {
    const { Icon, name } = this.selectedIcon;

    return (
      <>
        <Button type="button" title={name} onClick={this.handleOpenClick}>
          <Icon />
        </Button>
        <ExpandButton type="button" onClick={this.handleOpenClick}>
          <ChevronDown />
        </ExpandButton>
      </>
    )
  }

  renderIcons() {
    const { icons } = this.props;
    const { isOpen } = this.state;

    return (
      <IconListWrap isOpen={isOpen}>
        <IconListInner>
          {icons.map(({ name, Icon }) => (
            <IconItem
              key={name}
              className="icon-picker-select-button"
              onClick={this.handleChange(name)}
              title={name}
            >
              <Icon />
            </IconItem>
          ))}
        </IconListInner>
      </IconListWrap>
    );
  }

  render() {
    return (
      <IconPickerWrap onBlur={this.handleBlur}>
        {this.renderButtons()}
        {this.renderIcons()}
      </IconPickerWrap>
    );
  }
}

export default IconPicker;
