import styled from "styled-components";
import { color } from "../../theme";

export const Title = styled.h1`
  background-color: ${({ theme: t }) => color(t, "cardinal")};
  color: white;
  padding: 1rem 2.5rem;
  margin: 0;
  font-size: 2.4rem;
  display: ${({ block }) => (block ? "block" : "inline-block")};
  margin-bottom: 0.1rem;
`;
