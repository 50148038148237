import authReducer from "./auth/auth.slice";
import ideReducer from "./ide/ide.slice";
import adminReducer from "./admin/admin.slice";
import examReducer from "./exam/exam.slice";
import enrolmentReducer from './enrolment/enrolment.slice';
import billingReducer from './billing/billing.slice';
import courseReducer from './course/course.slice';

export default {
  auth: authReducer,
  ide: ideReducer,
  admin: adminReducer,
  exam: examReducer,
  enrolment: enrolmentReducer,
  billing: billingReducer,
  course: courseReducer
};
