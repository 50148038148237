import React from 'react';
import Guide from './Guide';
import GuideIde from './GuideIde';

const Page = ({ page }) => {
  switch (page.view) {
    case 'Guide': {
      return <Guide page={page} />;
    }

    case 'GuideIde': {
      return <GuideIde page={page} />
    }

    default: {
      return <Guide page={page} />
    }
  }
}

export default Page;
