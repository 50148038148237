import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// import { toast } from 'react-toastify';
import { firestore, auth } from '../firebase';
import { setUser as _setUser } from '../redux/auth/auth.slice';
import { flattenFirebaseUser } from "../helpers/auth";
import {
  Wrap,
  Inner,
  Form,
  FormGroup,
  Label,
  Input,
  Error
} from './styled/Login.styled';
import { Radio } from './styled/Form.styled';
import Select from './styled/Select.styled';
import { Title } from './styled/Title.styled';
import { Button } from './styled/Button.styled';
import {
  CheckboxWrap,
  Checkbox,
  CheckboxLabel
} from './styled/Checkbox.styled';
import Terms from './Terms';
import StripeCheckout from 'react-stripe-checkout';
import { toast } from 'react-toastify';

const EnrolWrap = styled(Wrap)`
  height: auto;
`;

const EnrolInner = styled(Inner)`
  max-width: 980px;
`;

const titleOptions = ['Mr.', 'Mrs.', 'Miss.', 'Ms.', 'Dr.'];

const paymentRadioOptions = (startDate) => [
  { value: 'full', label: 'Pay by card - Full balance' },
  { value: 'deposit', label: 'Pay by card - £750 deposit - Remaining balance due 2 weeks prior to course start date' },
  { value: 'finance', label: 'Pay by finance' },
  { value: 'bacs', label: 'Pay by bank transfer (BACS)' },
].filter((option) => option.value !== 'deposit' || startDate - moment.utc().unix() > 60 * 60 * 24 * 14);

const EnrolSchema = Yup.object().shape({
  cohort: Yup.string().required('Required'),
  course: Yup.string().required('Required'),
  title: Yup.string().oneOf(titleOptions).required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  address: Yup.object().shape({
    building: Yup.string().required('Required'),
    street: Yup.string().required('Required'),
    town: Yup.string().required('Required'),
    county: Yup.string().required('Required'),
    postcode: Yup.string().required('Required'),
  }),
  billingAddress: Yup.object().when('employerPays', {
    is: true,
    then: Yup.object().shape({
      name: Yup.string().required('Required'),
      email: Yup.string().email().required('Required'),
      building: Yup.string().required('Required'),
      street: Yup.string().required('Required'),
      town: Yup.string().required('Required'),
      county: Yup.string().required('Required'),
      postcode: Yup.string().required('Required'),
    }),
  }),
  phone: Yup.string().required('Required'),
  emergencyContactName: Yup.string().required('Required'),
  emergencyContactPhone: Yup.string().required('Required'),
  reach: Yup.string().required('Required'),
  acceptedTerms: Yup.bool().oneOf(
    [true],
    'Please read and accept the terms and conditions'
  ),
  paymentMethod: Yup.string().required('Required'),
  employerPays: Yup.bool(),
  financeDepositAmount: Yup.number().when('paymentMethod', {
    is: 'finance',
    then: Yup.number().min(750, 'Minimum deposit is £750'),
  }),
  financeInstalments: Yup.number().when('paymentMethod', {
    is: 'finance',
    then: Yup.number()
      .min(6, 'You must make a minimum of 6 instalments')
      .max(12, 'You can make a maximum of 12 instalments'),
  }),
});

class Enrol extends Component {
  state = {
    cohorts: [],
    courses: [],
    submitting: false,
  };

  async componentDidMount() {
    await Promise.all([
      this.getCohorts(),
      this.fetchCourses(),
    ]);
  }

  async getCohorts () {
    const oneWeekAway = moment()
      .add(1, 'week')
      .toDate();

    const cohortsSnapshot = await firestore()
      .collection('cohorts')
      .where('startDate', '>=', oneWeekAway)
      .get();

    const cohorts = [];

    cohortsSnapshot.forEach(examDoc => {
      const cohort = {
        id: examDoc.id,
        ...examDoc.data()
      };

      cohort.startDate = cohort.startDate.seconds;

      cohorts.push(cohort);
    });

    this.setState({
      cohorts: cohorts.sort(
        (cohortA, cohortB) => cohortA.startDate - cohortB.startDate
      )
    });
  };

  async fetchCourses() {
    const { docs } = await firestore().collection('courses').get();

    const courses = docs.map(d => ({
      id: d.id,
      ...d.data()
    }));

    this.setState({ courses });
  }

  handleSubmit = async (values, token, amount) => {
    const { user, setUser } = this.props;

    this.setState({ submitting: true });

    const idToken = await auth().currentUser.getIdToken(true);
    localStorage.setItem("idToken", idToken);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/enrol`,
        {
          ...values,
          stripeToken: token.id,
          paymentAmount: amount,
          billingAddress: (values.paymentMethod === 'bacs' && values.employerPays
            ? values.billingAddress
            : {
              ...values.address,
              name: `${values.title} ${values.firstName} ${values.lastName}`,
              email: user.email,
            }
          )
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      await toast.success('You have enrolled successfully.');
    } catch (error) {
      const message = error.response ? error.response.data.message : error.message;
      await toast.error(message);
    }

    this.setState({ submitting: false });
    this.props.history.push('/');

    const profileDoc = await firestore()
      .doc(`users/${user.uid}`)
      .get();

    setUser(
      flattenFirebaseUser({
        ...user,
        ...profileDoc.data()
      })
    );
  }

  renderBACSPaymentForm(values, errors, touched, handleChange, handleBlur) {
    return (
      <React.Fragment>
        <FormGroup>
          <p><b>Account Name: MCRCODES LTD</b></p>
          <p><b>Account Number: 32725116</b></p>
          <p><b>Sort Code: 56-00-49</b></p>
          <CheckboxWrap pad>
            <Checkbox
              id="employerPays"
              name="employerPays"
              type="checkbox"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <CheckboxLabel htmlFor="employerPays">
              My employer is going to pay my fees
            </CheckboxLabel>
          </CheckboxWrap>

        </FormGroup>
        {values.employerPays ? (
          <React.Fragment>
            <FormGroup>
              <Label>Please enter your employers billing information</Label>
            </FormGroup>

            <FormGroup>
              <Label>Name</Label>
              <Input name="billingAddress.name" type="text" />
              {errors.billingAddress && errors.billingAddress.name
                && touched.billingAddress && touched.billingAddress.name ? (
                  <Error>{errors.billingAddress.name}</Error>
                ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Email</Label>
              <Input name="billingAddress.email" type="text" />
              {errors.billingAddress && errors.billingAddress.email
                && touched.billingAddress && touched.billingAddress.email ? (
                  <Error>{errors.billingAddress.email}</Error>
                ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Building Number/Name</Label>
              <Input name="billingAddress.building" type="text" />
              {errors.billingAddress && errors.billingAddress.building
                && touched.billingAddress && touched.billingAddress.building ? (
                  <Error>{errors.billingAddress.building}</Error>
                ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Street</Label>
              <Input name="billingAddress.street" type="text" />
              {errors.billingAddress && errors.billingAddress.street
                && touched.billingAddress && touched.billingAddress.street ? (
                  <Error>{errors.billingAddress.street}</Error>
                ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Town/City</Label>
              <Input name="billingAddress.town" type="text" />
              {errors.billingAddress && errors.billingAddress.town
                && touched.billingAddress && touched.billingAddress.town ? (
                  <Error>{errors.billingAddress.town}</Error>
                ) : null}
            </FormGroup>

            <FormGroup>
              <Label>County</Label>
              <Input name="billingAddress.county" type="text" />
              {errors.billingAddress && errors.billingAddress.county
                && touched.billingAddress && touched.billingAddress.county ? (
                  <Error>{errors.billingAddress.county}</Error>
                ) : null}
            </FormGroup>

            <FormGroup>
              <Label>Postcode</Label>
              <Input name="billingAddress.postcode" type="text" />
              {errors.billingAddress && errors.billingAddress.postcode
                && touched.billingAddress && touched.billingAddress.postcode ? (
                  <Error>{errors.billingAddress.postcode}</Error>
                ) : null}
            </FormGroup>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }

  renderFinancePaymentForm(values, errors, touched) {
    return (
      <React.Fragment>
        <FormGroup>
          <Label>Deposit Amount (£)</Label>
          <Input name="financeDepositAmount" type="number" min={750} />
          {errors.financeDepositAmount && touched.financeDepositAmount ? (
              <Error>{errors.financeDepositAmount}</Error>
            ) : null}
        </FormGroup>

        <FormGroup>
          <Label>Number of Monthly Repayments</Label>
          <Input name="financeInstalments" type="number" min={6} max={12} />
          {errors.financeInstalments && touched.financeInstalments ? (
              <Error>{errors.financeInstalments}</Error>
            ) : null}
        </FormGroup>
      </React.Fragment>
    );
  }

  renderPaymentSection(values, errors, touched, handleChange, handleBlur) {
    const cohort = this.state.cohorts.find(c => c.id === values.cohort);
    const options = paymentRadioOptions(cohort.startDate);

    if (!options.some(opt => opt.value === values.paymentMethod)) {
      return null;
    }

    switch(values.paymentMethod) {
      case 'bacs': {
        return this.renderBACSPaymentForm(values, errors, touched, handleChange, handleBlur);
      }

      case 'finance': {
        return this.renderFinancePaymentForm(values, errors, touched, handleChange, handleBlur);
      }

      case 'full':
      case 'deposit':
      default: {
        return null;
      }
    }
  }

  renderSubmitButton(values, isValid) {
    if (['deposit', 'full', 'finance'].includes(values.paymentMethod)) {
      if (!isValid) {
        return (
          // the form is invalid so type="submit" will just show the validation errors
          <Button type="submit" stretch color="middleBlue">
            Checkout and Complete Enrolment
          </Button>
        );
      }

      const { user } = this.props;
      const { cohorts } = this.state;

      let amount = 75000; // Deposit amount

      if (values.paymentMethod === 'full') {
        const cohort = cohorts.find(c => c.id === values.cohort)

        amount = cohort.cost * (values.womensDiscount ? 0.9 : 1);
      } else if (values.paymentMethod === 'finance') {
        amount = parseInt(values.financeDepositAmount, 10) * 100;
      }

      return (
        <StripeCheckout
          name="Manchester Codes"
          token={(token) => this.handleSubmit(values, token, amount)}
          image="https://mcrcodes.s3.eu-west-2.amazonaws.com/MCR_Codes_M_CMYK_slack.png"
          amount={amount}
          currency="GBP"
          email={user.email}
          stripeKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
        >
          <Button type="button" stretch color="middleBlue">
            Checkout and Complete Enrolment
          </Button>
        </StripeCheckout>
      );
    }

    return (
      <Button type="submit" stretch color="middleBlue">
        Complete Enrolment
      </Button>
    )
  }

  render() {
    const { cohorts, courses, submitting } = this.state;
    const { user } = this.props;

    if (user.enrolled) {
      return <Redirect to="/" />
    }

    if (!cohorts.length || !courses.length) {
      return null;
    }

    return (
      <EnrolWrap>
        <EnrolInner>
          <Title>Enrol</Title>
          <Formik
            initialValues={{
              cohort: cohorts[0].id,
              course: courses[0].id,
              title: '',
              firstName: user.firstName || '',
              lastName: user.lastName || '',
              address: {
                building: '',
                street: '',
                town: '',
                county: '',
                postcode: '',
              },
              billingAddress: {
                name: '',
                email: '',
                building: '',
                street: '',
                town: '',
                county: '',
                postcode: '',
              },
              phone: '',
              emergencyContactName: '',
              emergencyContactPhone: '',
              health: '',
              laptop: false,
              ubuntuSupport: false,
              reach: '',
              womensDiscount: false,
              acceptedTerms: false,
              paymentMethod: 'full',
              financeDepositAmount: 750,
              financeInstalments: 6,
              employerPays: false,
            }}
            validationSchema={EnrolSchema}
            onSubmit={this.handleSubmit}
          >
            {({ errors, touched, handleChange, handleBlur, values, isValid }) => (
              <Form>
                {submitting ? (
                  "Please wait while we process your enrolment..."
                ) : (
                  <React.Fragment>
                    <FormGroup>
                      <Label>Which course would you like to enrol on?</Label>
                      <Select
                        name="course"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.course}
                        disabled={courses.length < 2}
                      >
                        {courses.map(course => (
                          <option key={course.id} value={course.id}>
                            {course.name}
                          </option>
                        ))}
                      </Select>
                      {errors.cohort && touched.cohort ? (
                        <Error>{errors.cohort}</Error>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>Which cohort would you like to join?</Label>
                      <Select
                        name="cohort"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cohort}
                      >
                        {cohorts.map(cohort => (
                          <option key={cohort.id} value={cohort.id}>
                            {moment.unix(cohort.startDate).format("Do MMM YYYY")} (£{cohort.cost / 100})
                          </option>
                        ))}
                      </Select>
                      {errors.cohort && touched.cohort ? (
                        <Error>{errors.cohort}</Error>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>Title</Label>
                      <Select
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      >
                        <option key="none" value="" />
                        {titleOptions.map(title => (
                          <option key={title} value={title}>
                            {title}
                          </option>
                        ))}
                      </Select>
                      {errors.title && touched.title ? (
                        <Error>{errors.title}</Error>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>First Name</Label>
                      <Input name="firstName" type="text" />
                      {errors.firstName && touched.firstName ? (
                        <Error>{errors.firstName}</Error>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>Last Name</Label>
                      <Input name="lastName" type="text" />
                      {errors.lastName && touched.lastName ? (
                        <Error>{errors.lastName}</Error>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>Address: House Number/Name</Label>
                      <Input name="address.building" type="text" />
                      {errors.address && errors.address.building
                        && touched.address && touched.address.building ? (
                          <Error>{errors.address.building}</Error>
                        ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>Address: Street</Label>
                      <Input name="address.street" type="text" />
                      {errors.address && errors.address.street
                        && touched.address && touched.address.street ? (
                          <Error>{errors.address.street}</Error>
                        ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>Address: Town/City</Label>
                      <Input name="address.town" type="text" />
                      {errors.address && errors.address.town
                        && touched.address && touched.address.town ? (
                          <Error>{errors.address.town}</Error>
                        ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>Address: County</Label>
                      <Input name="address.county" type="text" />
                      {errors.address && errors.address.county
                        && touched.address && touched.address.county ? (
                          <Error>{errors.address.county}</Error>
                        ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>Address: Postcode</Label>
                      <Input name="address.postcode" type="text" />
                      {errors.address && errors.address.postcode
                        && touched.address && touched.address.postcode ? (
                          <Error>{errors.address.postcode}</Error>
                        ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>Phone Number</Label>
                      <Input name="phone" type="tel" />
                      {errors.phone && touched.phone ? (
                        <Error>{errors.phone}</Error>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>Emergency Contact Name</Label>
                      <Input name="emergencyContactName" type="text" />
                      {errors.emergencyContactName &&
                        touched.emergencyContactName ? (
                          <Error>{errors.emergencyContactName}</Error>
                        ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>Emergency Contact Phone Number</Label>
                      <Input name="emergencyContactPhone" type="text" />
                      {errors.emergencyContactPhone &&
                        touched.emergencyContactPhone ? (
                          <Error>{errors.emergencyContactPhone}</Error>
                        ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>
                        Do you have any allergies, dietary requirements and/or
                        health conditions we should be aware of?
                      </Label>
                      <Input name="health" type="text" />
                      {errors.health && touched.health ? (
                        <Error>{errors.health}</Error>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>
                        You are required to bring your own laptop. If this isn't
                        possible, please check the box below.
                      </Label>
                      <CheckboxWrap pad>
                        <Checkbox
                          id="laptop"
                          name="laptop"
                          type="checkbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <CheckboxLabel htmlFor="laptop">
                          I need to borrow a laptop
                        </CheckboxLabel>
                      </CheckboxWrap>
                      {errors.laptop && touched.laptop ? (
                        <Error>{errors.laptop}</Error>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>
                        Our curriculum is geared towards users of OS X and Linux operating systems. If you are a Windows user then we suggest you install the free Linux distribution{" "}
                        <a
                          href="https://www.ubuntu.com"
                          target="_blank"
                          rel="noopener noreferrer">
                          Ubuntu
                        </a>
                        . We can support you with this following enrolment.
                      </Label>
                      <CheckboxWrap pad>
                        <Checkbox
                          id="ubuntuSupport"
                          name="ubuntuSupport"
                          type="checkbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <CheckboxLabel htmlFor="ubuntuSupport">
                          I would like assistance installing Ubuntu
                  </CheckboxLabel>
                      </CheckboxWrap>
                      {errors.ubuntuSupport && touched.ubuntuSupport ? (
                        <Error>{errors.ubuntuSupport}</Error>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>Please tell us how you heard about us</Label>
                      <Input name="reach" type="text" />
                      {errors.reach && touched.reach ? (
                        <Error>{errors.reach}</Error>
                      ) : null}
                    </FormGroup>

                    <FormGroup>
                      <Label>
                        We offer a 10% women's discount to encourage more women into
                        tech. If you identify as a woman, please check the box
                        below.
                      </Label>
                      <CheckboxWrap pad>
                        <Checkbox
                          id="womensDiscount"
                          name="womensDiscount"
                          type="checkbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <CheckboxLabel htmlFor="womensDiscount">
                          I identify as female
                  </CheckboxLabel>
                      </CheckboxWrap>
                      {errors.womensDiscount && touched.womensDiscount ? (
                        <Error>{errors.womensDiscount}</Error>
                      ) : null}
                    </FormGroup>

                    <h2>Payment</h2>
                    <FormGroup>
                      {paymentRadioOptions(cohorts.find(c => c.id === values.cohort).startDate).map((option, i, opts) => (
                        <Radio
                          key={option.value}
                          name="paymentMethod"
                          value={option.value}
                          checked={values.paymentMethod === option.value}
                          label={option.label}
                          validate={() => opts.some(opt => opt.value === values.paymentMethod) ? null : 'Required'}
                        />
                      ))}
                      {errors.paymentMethod && touched.paymentMethod ? (
                        <Error>{errors.paymentMethod}</Error>
                      ) : null}
                    </FormGroup>

                    {this.renderPaymentSection(values, errors, touched, handleChange, handleBlur)}

                    <FormGroup>
                      <h2>Terms and conditions</h2>
                      <div
                        style={{
                          height: 220,
                          overflowY: "scroll",
                          border: "1px solid #ddd",
                          padding: 20,
                          marginTop: 20
                        }}
                      >
                        <Terms />
                      </div>
                      <CheckboxWrap pad>
                        <Checkbox
                          id="acceptedTerms"
                          name="acceptedTerms"
                          type="checkbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <CheckboxLabel htmlFor="acceptedTerms">
                          I accept these terms and conditions
                        </CheckboxLabel>
                      </CheckboxWrap>
                      {errors.acceptedTerms && touched.acceptedTerms ? (
                        <Error>{errors.acceptedTerms}</Error>
                      ) : null}
                    </FormGroup>

                    {this.renderSubmitButton(values, isValid)}
                  </React.Fragment>
                )}
              </Form>
            )}
          </Formik>
        </EnrolInner>
      </EnrolWrap>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

const mapDispatchToProps = {
  setUser: _setUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Enrol);
