import React from 'react';
import Icon from './Icon';

const DeleteIcon = (props) => (
  <Icon
    {...props}
    path="M810 170v86h-596v-86h148l44-42h212l44 42h148zM256 810v-512h512v512q0 34-26 60t-60 26h-340q-34 0-60-26t-26-60z"
  />
);

export default DeleteIcon;
